<template>
	<div>
		<section class="slideshow featured">
			<article>
				<h1 class="text-white strong">Privacy</h1>
			</article>
			<img src="../assets/featured-image-sec_privacy.jpg" width="1920" height="361" alt="Privacy">
		</section>
		<div class="banner">
			<h4>
				{{ this.status }}
			</h4>
		</div>
		<main>
			<article class="contents">
				<h3>Privacy Policy</h3>
				<p>This is the
					official privacy policy for DonationPickUp.org. This Privacy
					Policy (“Policy”) governs the personally identifiable
					information (“Personal Information”) collected by this site
					only. </p>
				<p>Our site is
					intended to make it easy for you to schedule appointments for
					in-person pickup of items you want to donate to Vizavance and Oklahoma Foundation for the Disabled.&nbsp;
				</p>
				<h4>What Personal Information we need and why</h4>
				<p>When you schedule
					a pickup online through our site, we need to know certain
					information about you so that our truck drivers can fulfill your
					pickup request. We will request your name, email address,
					telephone number, and pickup address.&nbsp;
					We use this information primarily for fulfilling your
					pickup request. For example, your address is where our truck
					driver will go to pickup the items you wish to donate.&nbsp;
					Of course, before this, an email is sent to confirm the
					pickup date.&nbsp; Your
					information may be used to communicate regarding other donations
					or pickup requests.&nbsp;
					Finally, using your email address and password, you may login to
					our site and modify your account. This includes updating your
					Personal Information as well as scheduling, modifying, or
					canceling a pickup request.</p>
				<h4>How we protect your Personal Information</h4>
				<p>We do not sell,
					rent, lease or disclose your Personal Information to
					unaffiliated entities. When you provide Personal Information to
					us through our website, we share your information with our
					non-profit partners, Vizavance and Oklahoma Foundation for the
					Disabled. We will make your Personal Information available to
					non-affiliated third parties only in the following limited
					circumstances: (i) when we are compelled to do so by a
					governmental agency, court or other entity (e.g., to respond to
					subpoenas, court orders or legal process);&nbsp;
					(ii) if we believe your actions violate any law,
					regulation, our Terms of Use Agreement, or if you threaten the
					rights, property or safety of the site, operational service
					providers, suppliers, other customers or users, or any other
					party; (iii) if, in our sole discretion, we believe such
					disclosure to be necessary or appropriate to investigate or
					resolve possible problems or inquiries, to protect our business
					and assets, to defend our interests and/or to comply with our
					legal and regulatory obligations; or (iv) in the event of a
					bankruptcy, merger, acquisition, transfer of control, joint
					venture or other business combination involving us, or related
					companies. </p>
				<p>We know it takes
					confidence and trust to share Personal Information online. We
					endeavor to keep your confidence and maintain your trust by
					protecting your Personal Information. To that end, we maintain
					reasonable standards of security and confidentiality. However,
					despite our efforts, as with most Internet sites, it is possible
					that third parties may unlawfully intercept or access
					transmissions of private communications. Whenever you provide
					Personal Information online, you accept this risk, including
					when you share your Personal Information with us.
					Notwithstanding these inherent risks, we make a concerted good
					faith effort to ensure that your Personal Information is
					protected by maintaining the reasonable standards of security
					and confidentiality mentioned above.</p>
				<h4>Updates to this Policy</h4>
				<p>We reserve the
					right to change our Policy. If changes are made to our Policy,
					we will publish the updated version of our Policy here. However,
					please rest assured that if our Policy changes in the future, we
					will not use your Personal Information in a manner that is
					materially inconsistent with this Policy, without your prior
					consent. </p>
				<h3>TERMS OF USE</h3>
				<p>Welcome to
					DonationPickUp.org.&nbsp;
					We provide our services to you subject to the notices, terms,
					and conditions set forth in this Terms of Use Agreement
					(“Agreement”). By accessing or otherwise using our site, you
					agree to all of the terms in this Agreement. As such, we
					strongly advise you to read this Agreement carefully before
					using our site. </p>
				<h4>Minimum Age Requirement</h4>
				<p>You must be at
					least 18 years old to use our site. By using our site, you
					represent that you are at least 18 years old.
				</p>
				<h4>Use of DonationPickUp.org</h4>
				<p>Subject to the
					terms and conditions of this Agreement, Donation Pickup hereby
					grants you a limited, revocable, non-transferable, and
					non-exclusive license to access and use our site only for the
					purpose of scheduling appointments for in-person pickup of items
					you want to donate to Vizavance and/or Oklahoma Foundation for
					the Disabled. You may not make any other use of our site,
					including commercial uses, or use of our site on behalf of any
					third party, except through explicit advance permission in
					writing. Subject to the license provided above, you may not
					reproduce, distribute, display, sell, lease, transmit, create
					derivative works from, translate, modify, reverse-engineer,
					disassemble, decompile or otherwise exploit this site or any
					portion of it unless expressly permitted by Donation Pickup in
					writing. Donation Pickup reserves the right to refuse service,
					cancel pickup appointment requests, and/or terminate user
					accounts in its discretion, including, without limitation, if we
					believe that your conduct violates applicable law or is harmful
					to the best interests of Donation Pickup. By using our service
					you agree that this method and form of authenticating your
					identity is acceptable to you. You will be solely responsible
					for all access to and use of this site by anyone using your
					identification whether or not such access to and use of this
					site is actually authorized by you, including without
					limitation, all communications and transmissions and all
					obligations (including without limitation financial obligations)
					incurred through unauthorized access or use.
				</p>
				<h4>Links to other sites</h4>
				<p>Our site may
					contain links to other sites, such as our non-profit partners,
					Vizavance and Oklahoma Foundation for the Disabled. You agree
					that Donation Pickup is not responsible for these third party
					sites, inclusive of problems related to their operation or
					content. </p>
				<h4>Intellectual Property</h4>
				<p>All text,
					graphics, photographs, icons, buttons, audio, video, and
					software (collectively “Content”) belongs to Donation Pickup or
					its content partners. In addition to protection of each
					copyrighted work comprising our Content, the collection and
					arrangement of all such Content contained on our site is
					protected as a compilation under United States and international
					copyright laws. Except as provided in the license above, you may
					not use our Content without our express advance written
					permission.</p>
				<h4>Disclaimer and Limitation of Liability</h4>
				<p>This site and the
					services provided in connection with it are provided on an “AS
					IS” basis. Donation Pickup makes no representations or
					warranties of any kind, express or implied, as to the operation
					of our site or the services provided in connection with our
					site. To the fullest extent permitted by law, we disclaim all
					warranties either express or implied. Donation Pickup does not
					warrant that our site will be error free. From time to time,
					pickup requests scheduled through our site are not fulfilled.
					Donation Pickup shall in no way be liable to you for cancelled,
					terminated, or otherwise unfulfilled pickup requests. To the
					fullest extent permitted by law, we disclaim liability for any
					damages of any kind arising from your use of our site, including
					but not limited to indirect, incidental, punitive, exemplary,
					special or consequential damages. </p>
				<h4>&nbsp;Applicable Law and Disputes</h4>
				<p>This site is
					created and controlled by an Oklahoma entity. As such, the laws
					of the State of Oklahoma will govern these terms and conditions,
					without regard to conflicts of laws.</p>
				<h4>Updates</h4>
				<p>We reserve the
					right to change our Terms of Use Agreement. If changes are made
					to this Agreement, we will publish the updated version of our
					Agreement here. Your consent to any such changes is expressly
					given by your continued use of our site. </p>
				<h4>Effective Date</h4>
				<p>December 6, 2009</p>
			</article>
		</main>
	</div>

</template>

<script>
	import { bannerStatus } from '@/mixins/banner-status-mixin';
	export default {
		name: 'Privacy',
		mixins: [bannerStatus]

	}
</script>

<style scoped>

</style>
