import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Cookies from 'js-cookie'
import BootstrapVue, { VBToggle } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import XRegExp from 'xregexp'

import VueGtag from 'vue-gtag';
import { BTable } from 'bootstrap-vue'
import { VueReCaptcha } from 'vue-recaptcha-v3';
import {
	ModalPlugin,
	BFormInvalidFeedback,
	BForm,
	BFormGroup,
	BFormInput,
	BFormValidFeedback,
	BModal,
	VBModal,
	BButton,
	BFormRadio,
	BFormRadioGroup,
	BFormRow,
	BFormTextarea,
	BCarousel,
	BCarouselSlide,
	BInputGroupAppend,
	BInputGroupPrepend,
	BIcon,
	BIconCalendar,
	BInputGroup,
	BCollapse,
	BCard,
	BCardBody,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

import './vee-validate'


Vue.config.productionTip = false;

Vue.use( VueAxios, axios, Cookies, BootstrapVue, XRegExp, BTable, ModalPlugin, ValidationProvider, ValidationObserver,
	BFormInvalidFeedback, BForm, BFormGroup, BFormInput, BFormValidFeedback,
	BModal, VBModal, BButton, BFormRadio, BFormRadioGroup,
	BFormRow, BFormTextarea, BCarousel, BCarouselSlide,
	BInputGroupPrepend, BInputGroupAppend, BIcon, BIconCalendar, BInputGroup, BCard, BCollapse, BCardBody, VBToggle);
Vue.use(VueGtag, {
	config: {id: 'UA-157973179-1'},
	enabled: true
}, router);

Vue.use(VueReCaptcha, {siteKey: '6LfKBdoUAAAAALNiCTQt-N980PW8824Kr0ovv15a'});
// Vue.use(VueReCaptcha, {siteKey: '6LfwDOMUAAAAADKHZJWNIHO_B26z2yuVPjqPa_Nn'});
Vue.component( 'validation-provider', ValidationProvider );
Vue.component( 'validation-observer' , ValidationObserver );
Vue.component( 'b-form-invalid-feedback', BFormInvalidFeedback );
Vue.component( 'b-form-valid-feedback', BFormValidFeedback );
Vue.component( 'b-form', BForm );
Vue.component( 'b-form-row', BFormRow );
Vue.component( 'b-form-group', BFormGroup );
Vue.component( 'b-form-radio', BFormRadio );
Vue.component( 'b-form-radio-group', BFormRadioGroup );
Vue.component( 'b-form-input', BFormInput );
Vue.component( 'b-input-group', BInputGroup );
Vue.component( 'b-input-group-prepend', BInputGroupPrepend );
Vue.component( 'b-input-group-append', BInputGroupAppend );
Vue.component( 'b-icon-calendar', BIconCalendar );
Vue.component( 'b-icon', BIcon );
Vue.component( 'b-form-textarea', BFormTextarea );
Vue.component( 'b-carousel', BCarousel );
Vue.component( 'b-carousel-slide' , BCarouselSlide );
Vue.component( 'b-table', BTable );
Vue.component( 'b-modal', BModal );
Vue.component( 'b-button', BButton );
Vue.directive('b-modal', VBModal);
Vue.directive('b-toggle', VBToggle);
Vue.component('b-card', BCard);
Vue.component('b-collapse', BCollapse);

new Vue( {
	router,
	store,
	render: h => h( App )
} ).$mount( '#app' );



