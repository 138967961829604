<template>
	<div>
		<section class="slideshow featured">
			<article>
				<div v-if="this.pickupExists">
					<h1 class="text-white strong">Easy donations here.</h1>
					<router-link to="/modify-cancel-donation" class="btn btn-fancy">Modify your pickup</router-link>
				</div>
				<div v-else>
					<h1 class="text-white strong">Easy donations here.</h1>
					<router-link to="/donations" class="btn btn-fancy">Schedule a free pickup</router-link>
				</div>
			</article>
			<div>
				<b-carousel
						id="carousel-1"
						v-model="slide"
						:interval="4000"
						background="#ababab"
						img-width="1920"
						img-height="784"
				>
					<b-carousel-slide
							img-src="/images/home-slideshow-1.jpg"></b-carousel-slide>
					<b-carousel-slide
							img-src="/images/home-slideshow-2.jpg"></b-carousel-slide>
					<b-carousel-slide
							img-src="/images/home-slideshow-3.jpg"></b-carousel-slide>
				</b-carousel>
			</div>
		</section>
		<div class="banner">
			<h4>
				{{ this.status }}
			</h4>
		</div>
		<main>
			<article class="contents">
				<h2 class="text-center">Our online scheduling makes donating in select Oklahoma metro areas quick and easy. We
					can pick up your items within a week.</h2>
				<div class="row margin-top">
					<div class="col-md small-margin-top text-center">
						<p><img src="../assets/icon-1.png" width="75" height="75" alt="Online"></p>
						<h3>Online</h3>
						<ul class="lined">
							<li>Register for First Pickup</li>
							<li>Schedule New Pickups</li>
							<li>Modify/Cancel Pickup</li>
							<li>Printable Donation Labels</li>
						</ul>
					</div>
					<div class="col-md small-margin-top text-center">
						<p><img src="../assets/icon-2.png" width="75" height="75" alt="Simple"></p>
						<h3>Simple</h3>
						<ul class="lined">
							<li>Donate From Home</li>
							<li>Easy Furniture Donations</li>
							<li>Porch Pickups</li>
							<li>Large & Small Donations</li>
						</ul>
					</div>
					<div class="col-md small-margin-top text-center">
						<p><img src="../assets/icon-3.png" width="75" height="75" alt="Convenient"></p>
						<h3>Convenient</h3>
						<ul class="lined">
							<li>Schedule From Your Device</li>
							<li>Small Donations</li>
							<li>Quick Drop Offs</li>
							<li>24/7 Donating</li>
						</ul>
					</div>
				</div>

			</article>
		</main>
		<section class="image-bg image-bg_donations">
			<article>
				<div v-if="this.pickupExists">
					<h1 class="text-white strong">Easy donations here.</h1>
					<router-link to="/modify-cancel-donation" class="btn btn-fancy">Modify your pickup</router-link>
				</div>
				<div v-else>
					<h1 class="text-white strong">Easy donations here.</h1>
					<router-link to="/donations" class="btn btn-fancy">Schedule a free pickup</router-link>
				</div>

			</article>
			<ul class="rslides" id="donations">
				<li><img src="../assets/donation-bg-1.jpg" width="3840" height="1568" alt=""></li>
				<li><img src="../assets/donation-bg-2.jpg" width="3840" height="1568" alt=""></li>
			</ul>
		</section>
		<section class="contents">
			<article>
				<p class="text-center">DonationPickup.org provides free pickup services for your tax deductible donations
					including
					all acceptable items, clothing, and other household goods. We serve the Oklahoma City metropolitan area with
					at-home pickup of your donation.</p>
				<h3 class="text-center">Click below to print donation labels</h3>
				<div class="label-links">

					<p class="text-center"><a href="../assets/vizavance_label.pdf" target="_blank" class="btn pdf-link"
							@click.prevent="openPDF('./docs/vizavance_label.pdf')">Vizavance Label</a></p>
					<p class="text-center"><a href="../assets/okfd_label.pdf" target="_blank" class="btn pdf-link"
							@click.prevent="openPDF('./docs/okfd_label.pdf')">OKFD Label</a></p>
				</div>
			</article>
		</section>
	</div>
</template>
<script>
	import storeMixin from '@/mixins/store-mixin';
	import { bannerStatus } from '@/mixins/banner-status-mixin';

	export default {
		name: 'home',
		mixins: [ storeMixin, bannerStatus ],
		data() {
			return {
				slide: 0,
				sliding: null
			}
		},
		methods: {
			openPDF( path ) {
				window.open( path )
			}
		},
		onSlideStart( slide ) {
			this.sliding = true
		},
		onSlideEnd( slide ) {
			this.sliding = false
		}
	}
</script>

<style lang="scss" scoped>
	.btn.pdf-link {
		background-color: black;
	}

	.label-links {
		margin-top: 30px;
	}

	.margin-top {
		margin-top: 100px;
	}

	.lined li {
		padding-top: 10px;
		padding-bottom: 10px;
	}

	@media screen and (max-width: 590px) {
		.text-white-custom {
			font-size: 35px !important;
		}

		.col-md.small-margin-top {
			margin-top: 25px !important;
		}

		.btn.btn-fancy {
			height: 50px;
			margin-top: 25px;
			line-height: 50px;
			padding: 0 10px;
		}
	}

	.btn.btn-fancy:hover {
		color: #0099cc !important;
		text-decoration: none !important;
	}

	@media screen and (max-width: 760px) {
		.text-white-custom {
			font-size: 50px;
		}
	}

	.text-white-custom {
		color: #FF7D78;
	}
</style>
