import { mapGetters, mapActions } from 'vuex'

let storeMixin = {
	computed: {
		...mapGetters( {
			getDonorID: 'getDonorID',
			loggedIn: 'isLoggedIn',
			getpw: 'getpw',
			getPickupID: 'getPickupID',
			pickupExists: 'doesPickupExist',
			getUsername: 'getUsername',
			getFirstName: 'getFirstName',
			getPhone: 'getPhone',
			isSuccessLogin: 'getSuccess',
			getSession: 'getSession',
			getSpecialPickupDate: 'getSpecialPickupDate',
			getSpecialPickupDay: 'getSpecialPickupDay',
			getNextPickupDate: 'getNextPickupDate',
			getENoticePickupDate: 'getENoticePickupDate'


		} )
	},
	methods: {
		...mapActions( {
			login: 'login',
			logout: 'logout',
			setstate: 'setstate'
		} )
	}
};

export default storeMixin
