<template>
	<div id="app">
		<header id="top">
			<article>
				<div class="nav-icon">
					<span></span>
					<span></span>
					<span></span>
					<span></span>
				</div>
				<div class="topper">
					<ul>
						<li v-if="!this.loggedIn">
							<router-link to="/login">Login</router-link> <!-- TODO:  Show Login/Logout based on if loggedIn-->
						</li>
						<li v-if="this.loggedIn">
							Hello {{ this.getFirstName }},<a @click.prevent="logoutRefresh">Logout</a>
							<!-- TODO:  Show Login/Logout based on if loggedIn-->
						</li>
						<li v-if="this.loggedIn">
							<router-link to="/donor-information">Donor Info</router-link>
						</li>
						<li v-if="!this.loggedIn">
							<router-link to="/register">Register</router-link>
						</li>
						<li>
							<router-link to="/privacy">Privacy</router-link>
						</li>
					</ul>
				</div>
				<div class="logo">
<!--					<router-link to="/home"><span class="align-middle"><img src="./assets/logo.png" width="320" height="80"-->
<!--							alt=""></span></router-link>-->
                    <a href="https://donationpickup.org"><span class="align-middle"><img src="./assets/logo.png" width="320" height="80"
							alt=""></span></a>
				</div>
				<nav class="donation-nav">
					<ul class="main-nav font-regular">
						<li v-if="!this.loggedIn" class="mobile-topper">
							<router-link to="/login">Login</router-link> <!-- TODO:  Show Login/Logout based on if loggedIn-->
						</li>
						<li v-if="this.loggedIn" class="mobile-topper">
							Hello {{ this.getFirstName }},<a @click.prevent="logoutRefresh">Logout</a>
							<!-- TODO:  Show Login/Logout based on if loggedIn-->
						</li>
						<li class="mobile-topper">
							<router-link to="/register">Register</router-link>
						</li>
						<li class="mobile-topper">
							<router-link to="/privacy">Privacy</router-link>
						</li>
<!--						<li class="alink">-->
<!--							<router-link to="/home">Home</router-link>-->
<!--						</li>-->
<!--						<li>-->
<!--							<router-link to="/about-us">About</router-link>-->
<!--						</li>-->
						<li v-if="this.loggedIn">
							<router-link to="/donations" v-if="!this.pickupExists">Donations</router-link>
							<router-link to="/modify-cancel-donation" v-if="this.pickupExists">Donations</router-link>
							<ul class="level2">
								<li class="logged-in-only" v-if="!this.pickupExists">
									<router-link to="/donations">Schedule New Pickup</router-link>
								</li>
								<li class="logged-in-only" v-if="this.pickupExists">
									<router-link to="/modify-cancel-donation">Modify/Cancel Pickup</router-link>
								</li>
								<li class="logged-in-only">
									<router-link to="/review-past-donations">Review Past Donations</router-link>
								</li>
								<li class="logged-in-only">
									<router-link to="/donor-information">Donor Information</router-link>
								</li>

							</ul>
						</li>
<!--						<li>-->
<!--							<router-link to="/acceptable-items">Acceptable Items</router-link>-->
<!--						</li>-->
<!--						<li>-->
<!--							<router-link to="/faq">FAQ</router-link>-->
<!--						</li>-->
<!--						<li>-->
<!--							<router-link to="/contact">Contact</router-link>-->
<!--						</li>-->
					</ul>
				</nav>
			</article>
		</header>
		<router-view/>
		<footer class="contents text-center">
			<article>
				<hr class="donation">
				<p v-if="this.pickupExists">
					<router-link to="/modify-cancel-donation" class="btn btn-fancy">Modify your pickup</router-link>
				</p>
				<p v-else-if="!this.loggedIn">
					<router-link to="/login" class="btn btn-fancy">Schedule a Free pickup</router-link>
				</p>
				<p v-else-if="this.loggedIn">
					<router-link to="/donations" class="btn btn-fancy">Schedule a Free pickup</router-link>
				</p>

				<p><a href="mailto:donorservices@donationpickup.org">donorservices@donationpickup.org</a><br>
					&copy; 2020 DonationPickup.org. Website by <a href="http://www.myheartcreative.com"
							class="siteby">myheartcreative</a> and <a href="https://360works.com/" class="siteby">360Works</a>.</p>
				<ul class="social">
					<li><a href="http://www.facebook.com/pages/Donationpickuporg/186739551336112"><span
							class="symbol">&#xe227;</span></a>
					</li>
				</ul>
				<p class="logos">
					<a href="https://vizavance.org/" target="_blank"><img src="./assets/logo-vizadvance.jpg" width="122"
							height="78"
							alt="Vizavance"></a>
					<a href="http://www.okfd.org/" target="_blank"><img src="./assets/logo-okfd.jpg" width="107" height="78"
							alt="OKFD"></a>
				</p>
			</article>
		</footer>
	</div>
</template>

<script>
	import Cookies from 'js-cookie';
	import storeMixin from '@/mixins/store-mixin';

	export default {
		name: 'App',
		mixins: [ storeMixin ],
		data() {
			return {}
		},
		created() {

		},
		mounted: function () {
			let parser = new UAParser();

			let result = parser.getResult();

			let os = result.os.name.toLowerCase();
			let devices = 'android||avantgo||blackberry||blazer||compal||elaine||fennec||hiptop||ios||iemobile||ip(hone|od)||iris||kindle||lge||maemo||midp||mmp||opera m(ob|in)i||palm( os)?||phone||p(ixi|re)\\/||plucker||pocket||psp||symbian||treo||up\\.(browser|link)||vodafone||wap||windows (ce|phone)||xda||xiino)'.split( '||' )

			let bodyElement = $( 'body' );
			if ( devices.indexOf( os ) !== -1 ) {
				bodyElement.addClass( 'mobile' )
			} else {
				bodyElement.addClass( 'not-mobile' )
			}
		},
		methods: {
			logoutRefresh() {
				this.logout();
				this.$router.push( {
					name: 'home'
				} )
                // window.location.href = "https://donationpickup.org";
			}
		}
	}
</script>

<style lang="scss">
	#app {
		font-family: 'Muli', sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		/*text-align: center;*/
		color: #2c3e50;
	}

	@media screen and (max-width: 760px) {
		.form-check-inline {
			display: block;
		}

		.form-check.form-check-inline:not(:first-child) {
			margin-left: 0;
		}

		.location .form-check.form-check-inline,
		.size .form-check.form-check-inline {
			display: flex;
		}

		.form-check.form-check-inline label {
			margin-left: 5px;
		}

	}

	.form-pickup {
		margin-bottom: 20px;
	}

	.form-check-inline:not(:first-child) {
		margin-left: 10px;
	}

	.form-check-inline:last-child {
		margin-right: 0;
	}

	.form-check-inline .form-check-input {
		margin-right: 10px;
	}

	span.form-bold {
		font-weight: bold;
	}

	.form-pickup-logo {
		width: 80%;
		padding: 10%;
	}

	.form-pickup-logo img {
		width: 100%;
	}


	.form-pickup-notice {
		margin-bottom: 20px;
	}

	.donation-form button {
		margin-top: 10px;
	}

	.display-right {
		text-align: right;
	}

	.tableInfo {
		display: flex;
		flex: 0 0 83.333333%;
		max-width: 83.333333%;
		margin-bottom: 10px;
	}

	.btn-fancy.small {
		/* height: 25px;
		line-height: 25px;
		font-size: 18px;
		padding: 0;
		padding-left: 5px;
		padding-right: 5px; */
	}

	.mb-10 {
		margin-bottom: 10px;
	}

	.form-pickup {
		text-align: left;
	}

	.form-pickup input {
		/*margin-left: 5px;*/
	}

	body {
		font: 18px/2 "Muli", sans-serif;
		color: #000;
		height: 100%;
		width: 100%;
		margin: 0;
		padding: 0;
		background: #fff;
	}

	.display-none {
		display: none;
	}

	strong {
		font-weight: 700;
		font-size: 18px;
	}

	hr.donation {
		border: 1px solid #00b8f4;
		border-width: 0 0 2px;
		border-collapse: collapse;
		clear: both;
		margin: 35px 0;
	}

	main {
		padding-top: 0px;
	}

	.banner {
		height: auto;
		width: 75%;
		margin: auto;
		padding-top: 10px;
		/*padding-bottom: 25px;*/
		margin-bottom: 25px;

	}

	.banner h4 {
		color: red;
	}

	.mobile .banner {
		height: auto;
	}

	.mobile .banner h4 {
		font-size: 18px;
	}

	.banner .alert {
		margin-bottom: 0px;
		height: initial;
	}

	.contents input[type="text"],
	.contents input[type="password"],
	.contents textarea,
	.contents select {
		border: 1px solid #bcbbb6;
		padding: 12px 10px;
		border-radius: 5px;
		outline: none;
		margin-bottom: 10px;
		width: 100%;
	}

	header.modal-header {
		height: auto;
		position: inherit;
		display: none;
	}

	footer.modal-footer {
		height: auto;
		position: inherit;
		/*display: none !important;*/
		border-top: 0;
	}

	footer.modal-footer .btn-secondary {
		display: none;
	}

	.sec-page .bg-success {
		background-color: rgba(41, 156, 68, 0.5) !important;

	}

	.sec-page .bg-danger {
		background-color: rgba(178, 38, 52, 0.8) !important;
	}

	.col-form-label {
		margin-bottom: 0.5rem !important;
		font-weight: 500 !important;
		line-height: 1.2 !important;
		font-size: 1.5rem !important;
	}

	.invalid-feedback {
		display: block !important;
	}

	.form-group {
		width: 100%;
	}

	.contents input[type="text"].is-invalid,
	.contents input[type="password"].is-invalid,
	.contents textarea.is-invalid,
	.contents select.is-invalid {
		border: 1px solid #dc3545;
		background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
		background-repeat: no-repeat;
		padding-right: calc(1.5em + 0.75rem);
		background-position: right calc(0.375em + 0.1875rem) center;
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
	}

	.col-form-label {
		padding-bottom: 0 !important;
	}

	.custom-control-label:after, .custom-control-label:before {
		top: 10px !important;
	}

	.not-mobile .mobile-topper {
		display: none;
	}

	.mobile li.mobile-topper {
		color: white;
		font-size: 18px;
		line-height: 20px;
	}

	.mobile li.mobile-topper a {
		margin-top: 10px;
	}

	iframe[role="presentation"] {
		margin: 0;

	}

	.ui-widget.ui-widget-content {
		border: none;
	}


	@media screen and (max-width: 590px) {
		.btn.btn-fancy {
			height: 50px;
			margin-top: 0 !important;
			line-height: 50px;
			padding: 0 10px;
		}
	}

	@media screen and (max-width: 768px) {
		.not-mobile .mobile-topper {
			display: block;
		}
	}

	@media screen and (min-width: 1260px ) {
		.mobile .mobile-topper {
			display: none;
		}
	}

	.grecaptcha-badge {
		display: none !important;
	}

	/*	button.btn.btn-sm.btn-outline-secondary {*/
	/*		background-color: #00b8f4;*/
	/*	}*/

	/*	button.btn.btn-sm.btn-outline-secondary.disabled {*/
	/*    background-color: transparent;*/
	/*}*/
</style>
