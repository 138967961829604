<template>
	<div>
		<section class="slideshow featured">
			<article>
				<h1 class="text-white strong">FAQ</h1>
			</article>
			<img src="../assets/featured-image-sec_faq.jpg" width="1920" height="361" alt="FAQ">
		</section>
		<div class="banner">
			<h4>
				{{ this.status }}
			</h4>
		</div>
		<main>
			<article class="contents"> <!-- Links to questions -->
				<h3>Questions</h3>
				<div class="row">
					<div class="col-md mb-10">
						<ul>
							<li><a href="#faq_13" title="How do I register and what are the benefits?">How do I register and what
								are
								the
								benefits?</a></li>
							<li><a href="#faq_14" title="Can I get email and text message notifications?">Can I get email and text
								message
								notifications?</a></li>
							<li><a href="#faq_16" title="Is my donation tax deductible?">Is my donation tax deductible?</a></li>
							<li><a href="#faq_17" title="I did not receive a receipt. How do I get one?">I did not receive a
								receipt.
								How do I get one?</a></li>
							<li><a href="#faq_18" title="How do I identify items that I intend to donate?">How do I identify items
								that I
								intend to donate?</a></li>
							<li><a href="#faq_19" title="What time will the truck come?">What time will the truck come?</a></li>
							<li><a href="#faq_20" title="Will you pickup in bad weather?">Will you pickup in bad weather?</a></li>
						</ul>
					</div>
					<div class="col-md">
						<ul>
							<li><a href="#faq_21" title="Can I cancel or make changes for a scheduled pickup?">Can I cancel or make
								changes for a scheduled pickup?</a></li>
							<li><a href="#faq_22" title="My pickup was missed, what should I do?">My pickup was missed, what should
								I
								do?</a></li>
							<li><a href="#faq_23"
									title="The trucks do not pick up in my area or I live in an apartment. Can I drop off a donation?">The
								trucks do not pick up in my area or I live in an apartment. Can I drop off a donation?</a></li>
							<li><a href="#faq_24" title="Where do my donated items go?">Where do my donated items go?</a></li>
						</ul>
					</div>
				</div>

				<h3>Answers</h3>
				<!-- Full questions/answers -->
				<div id="faq_13">
					<dl>
						<dt id="faqItemQuestion14">How do I register and what are the benefits?</dt>
						<dd id="faqItemAnswer14">
							<p>
								<router-link to="/register">Register</router-link>
								, fill out your information, and click Complete Registration. It is
								that
								easy! You will receive an email with
								your temporary password that will allow you to log in. Here
								you can schedule, modify or cancel a pick up and view or print
								a list of your donation history. You may also sign up for
								eNotice.<a name="3"></a></p>
							<div class="top"><a href="#"><small>Back to Top</small></a></div>
						</dd>
					</dl>
				</div>
				<div id="faq_14">
					<dl>
						<dt id="faqItemQuestion15">Can I get email and text message notifications?</dt>
						<dd id="faqItemAnswer15">
							<p>Yes, register for eNotice and text messaging when we are going to be in your area. <a name="3"></a></p>
							<div class="top"><a href="#"><small>Back to Top</small></a></div>
						</dd>
					</dl>
				</div>
				<div id="faq_16">
					<dl>
						<dt id="faqItemQuestion16">Is my donation tax deductible?</dt>
						<dd id="faqItemAnswer16">
							<p>Yes! All items donated are deductible for income tax purposes at their present fair market value.
								Internal
								Revenue code places the responsibility for estimating this value upon the donor rather than the agency
								receiving the gift. As with any tax-related information, an individual donor should always consult a tax
								professional to resolve questions regarding their particular donation. Please consult form 8283 and
								publication 526 and 561 on the Internal Revenue Service website. <a href="http://www.irs.gov"
										target="_blank">www.irs.gov</a></p>
							<div class="top"><a href="#"><small>Back to Top</small></a></div>
						</dd>
					</dl>
				</div>
				<div id="faq_17">
					<dl>
						<dt id="faqItemQuestion17">I did not receive a receipt. How do I get one?</dt>
						<dd id="faqItemAnswer17">
							<p>Login and click on the Review Past Donations button. Click on the receipt link to display and/or print
								a
								duplicate receipt. If you prefer to receive a receipt by mail, call (866) 657-4258 or email your request
								to
								<a href="mailto:donorservices@donationpickup.org">donorservices@donationpickup.org</a>.</p>
							<div class="top"><a href="#"><small>Back to Top</small></a></div>
						</dd>
					</dl>
				</div>
				<div id="faq_18">
					<dl>
						<dt id="faqItemQuestion18">How do I identify items that I intend to donate?</dt>
						<dd id="faqItemAnswer18">
							<p> It is your responsibility to identify the items you wish to donate. Labels may be printed from this
								site. You should affix a label to all items you intend to donate to Vizavance and Oklahoma
								Foundation for the Disabled.</p>
							<p><span id="mce_marker"><strong>Click below to print labels</strong><br></span></p>
							<ul>
								<li><a href="./docs/vizavance_label.pdf" target="_blank"
										@click.prevent="openLabel('./docs/vizavance_label.pdf')">VIZ → Vizavance</a></li>
								<li><a href="./docs/okfd_label.pdf" target="_blank" @click.prevent="openLabel('./docs/okfd_label.pdf')">FD
									→ Oklahoma Foundation for the Disabled</a></li>
							</ul>
							<p>Furthermore, you should set the items you wish to donate outside your residence and at a reasonable
								distance away from any items on your property that you do not wish to donate. Please take special care
								not
								to place the items you wish to donate in confusingly close proximity to items that you do not wish to
								donate. Donation Pickup, Prevent Blindness of Oklahoma and Oklahoma Foundation for the Disabled will not
								be
								responsible for items accidentally picked up from your property because of your failure to abide by this
								policy.</p>
							<div class="top"><a href="#"><small>Back to Top</small></a></div>
						</dd>
					</dl>
				</div>
				<div id="faq_19">
					<dl>
						<dt id="faqItemQuestion19">What time will the truck come?</dt>
						<dd id="faqItemAnswer19">
							<p> The driver will come between 9 AM and dark. The driver plans the most efficient route. It is
								impossible to know how long each pickup will take, so we cannot provide exact times.</p>
							<div class="top"><a href="#"><small>Back to Top</small></a></div>
						</dd>
					</dl>
				</div>
				<div id="faq_20">
					<dl>
						<dt id="faqItemQuestion20">Will you pickup in bad weather?</dt>
						<dd id="faqItemAnswer20">
							<p>We pick up in all types of weather including rain and snow. If driving is unsafe due to weather/road
								conditions, we will not pick up that day and your pick up will be rescheduled.</p>
							<div class="top"><a href="#"><small>Back to Top</small></a></div>
						</dd>
					</dl>
				</div>
				<div id="faq_21">
					<dl>
						<dt id="faqItemQuestion21">Can I cancel or make changes for a scheduled pickup?</dt>
						<dd id="faqItemAnswer21">
							<p>Yes! By logging in and going to <router-link to="/modify-cancel-donation">Cancel/Modify Donation</router-link>,
								you
								may cancel your donation or make any changes such as change the date of the pick up. Changes must be
								made by
								midnight the day before the scheduled pick up.</p>
							<div class="top"><a href="#"><small>Back to Top</small></a></div>
						</dd>
					</dl>
				</div>
				<div id="faq_22">
					<dl>
						<dt id="faqItemQuestion22">My pickup was missed, what should I do?</dt>
						<dd id="faqItemAnswer22">
							<p>We apologize for any inconvenience this may have caused. Please call (866) 657-4258 or email us at <a
									href="mailto:donorservices@donationpickup.org">donorservices@donationpickup.org</a> and we will
								reschedule
								your pick up for the next business day.</p>
							<div class="top"><a href="#"><small>Back to Top</small></a></div>
						</dd>
					</dl>
				</div>
				<div id="faq_23">
					<dl>
						<dt id="faqItemQuestion23">The trucks do not pick up in my area or I live in an apartment. Can I drop off a
							donation?
						</dt>
						<dd id="faqItemAnswer23">
                            <p>Yes. You may drop off your donation at Bargain Thrift Store. Located at
                                <a href="http://maps.google.com/?q=4545 NW 16th Street, Oklahoma City, Oklahoma, 73127" target="_blank">4545 NW 16th Street, Oklahoma City, Oklahoma 73127</a>
                            </p>
							<div class="top"><a href="#"><small>Back to Top</small></a></div>
						</dd>
					</dl>
				</div>
				<div id="faq_24">
					<dl>
						<dt id="faqItemQuestion24">Where do my donated items go?</dt>
						<dd id="faqItemAnswer24">
							<p>The donations are sold in bulk to Bargain Thrift Store and resold in second-hand stores in the
								area. Proceeds fund the operations of the charitable organizations. The stores provides
								affordable clothing to Oklahomans and employ individuals in the community. Vizavance and the
								Oklahoma Foundation for the Disabled do not own or operate these thrift stores. For more
								information, <a href="http://www.bargainthrift.com/" target="_blank">visit www.bargainthrift.com</a>.
							</p>
							<div class="top"><a href="#"><small>Back to Top</small></a></div>
						</dd>
					</dl>
				</div>
			</article>
		</main>
	</div>
</template>

<script>
	import { bannerStatus } from '@/mixins/banner-status-mixin';
	export default {
		name: 'FAQ',
		methods: {
			openLabel( path ) {
				window.open( path )
			}
		},
		mixins: [bannerStatus]
	}
</script>

<style scoped>
	.contents ul li::before {
		top: 10px;
	}
</style>
