import Vue from 'vue'
import Vuex from 'vuex'
import Cookies from 'js-cookie'

Vue.use( Cookies );
Vue.use( Vuex );

const LOGIN = 'LOGIN';
const LOGOUT = 'LOGOUT';
const SETSTATE = 'SETSTATE';

export default new Vuex.Store( {
	state: {
		pw: Cookies.get( 'pw' ),
		username: Cookies.get( 'username' ),
		donorID: Cookies.get( 'donorID' ),
		pickupExists: Cookies.get( 'pickupExists' ),
		loggedIn: Cookies.get( 'loggedIn' ),
		firstName: Cookies.get( 'firstName' ),
		nextPickupDate: Cookies.get( 'nextPickupDate' ),
		pickupID: Cookies.get( 'pickupID' ),
		phone: Cookies.get( 'phone' ),
		success: Cookies.get( 'success' ),
		session: Cookies.get( 'session' ),
		specialPickupDay: Cookies.get('specialPickupDay'),
		specialPickupDate: Cookies.get('specialPickupDate'),
		eNoticePickupDate: Cookies.get('eNoticePickupDate')
	},
	mutations: {
		[ LOGIN ]( state, payload ) {
			for ( let cookie in payload ) {
				if ( payload.hasOwnProperty( cookie ) ) {
					state[ cookie ] = payload[ cookie ];
					Cookies.set( cookie, payload[ cookie ] )
				}
			}
		},
		[ LOGOUT ]( state ) {
			for ( let cookie in state ) {
				if ( state.hasOwnProperty( cookie ) ) {
					Cookies.remove( cookie );
					state[ cookie ] = undefined;
				}
			}
		},
		[ SETSTATE ]( state, payload ) {
			for ( let cookie in payload ) {
				if ( payload.hasOwnProperty( cookie ) ) {
					state[ cookie ] = payload[ cookie ];
					Cookies.set( cookie, payload[ cookie ] )
				}
			}
		}
	},
	getters: {
		getDonorID: state => {
			return state.donorID === undefined ? '' : state.donorID
		},
		isLoggedIn: state => {
			return state.loggedIn === undefined ? '' : state.loggedIn
		},
		doesPickupExist: state => {
			return state.pickupExists === undefined ? '' : state.pickupExists
		},
		getpw: state => {
			return state.pw === undefined ? '' : state.pw
		},
		getUsername: state => {
			return state.username === undefined ? '' : state.username
		},
		getPickupID: state => {
			return state.pickupID === undefined ? '' : state.pickupID
		},
		getNextPickupDate: state => {
			return state.nextPickupDate === undefined ? '' : state.nextPickupDate
		},
		getFirstName: state => {
			return state.firstName === undefined ? '' : state.firstName
		},
		getPhone: state => {
			return state.phone === undefined ? '' : state.phone
		},
		getSuccess: state => {
			return state.success === undefined ? '' : state.success
		},
		getSession: state => {
			return state.session === undefined ? '' : state.session
		},
		getSpecialPickupDate: state => {
			return state.specialPickupDate === undefined ? '' : state.specialPickupDate
		},
		getSpecialPickupDay: state => {
			return state.specialPickupDay === undefined ? '' : state.specialPickupDay
		},
		getENoticePickupDate: state => {
			return state.eNoticePickupDate === undefined ? '' : state.eNoticePickupDate
		}
	},
	actions: {
		login( { commit }, payload ) {
			commit( LOGIN, payload )
		},
		logout( { commit } ) {
			commit( LOGOUT );
		},
		setstate( { commit }, payload ) {
			commit( SETSTATE, payload )
		}
	}
} )
