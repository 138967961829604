<template>
	<div>

		<section class="slideshow featured">
			<article>
				<h1 class="text-white strong">Change Password</h1>
			</article>
			<img src="../assets/featured-image-sec_login.jpg"
					width="1920"
					height="361" alt="Login">
		</section>
		<div class="banner">
			<b-modal id="modal-center" centered v-model="alertShow"
					:body-bg-variant="responseVariant"
					:header-bg-variant="responseVariant"
					:footer-bg-variant="responseVariant"
					:no-close-on-backdrop="true"
					:no-close-on-esc="true"
					@ok="clearAlert"
			>
				<p class="my-4">{{alertMessage}}</p>
			</b-modal>
			<h4>
				{{ this.status }}
			</h4>
		</div>
		<main>
			<article class="contents">
				<div class="container">
					<div class="row">
						<div class="col">
							<h4>Change Password</h4>
							<ValidationObserver v-slot="{handleSubmit}">
								<b-form class="donation-form" @submit.prevent="handleSubmit(formIsValid)">
									<div class="row">
										<div class="col-md">
											<div class="form-pickup">
												<ValidationProvider rules="required" v-slot="{valid, errors}" name="Password" vid="password">
													<b-form-group label="Password">
														<b-form-input type="password" v-model="password" id="password"
																:state="errors[0] ? false : (valid ? true : null)">
														</b-form-input>
														<b-form-invalid-feedback id="inputLiveFeedback">{{ errors[0] }}</b-form-invalid-feedback>
													</b-form-group>
												</ValidationProvider>
											</div>
										</div>
										<div class="col-md">

											<div class="form-pickup">
												<ValidationProvider rules="required|confirmed:password" v-slot="{valid, errors}"
														name="Confirm Password">
													<b-form-group label="Confirm Password">
														<b-form-input type="password" v-model="passwordCheck"
																:state="errors[0] ? false : (valid ? true : null)">
														</b-form-input>
														<b-form-invalid-feedback id="inputLiveFeedback">{{ errors[0] }}</b-form-invalid-feedback>
													</b-form-group>
												</ValidationProvider>
											</div>
										</div>
									</div>
									<div class="text-left">
										<button type="submit" class="btn btn-primary">Change Password</button>
									</div>
								</b-form>
							</ValidationObserver>

						</div>
					</div>
				</div>
			</article>
		</main>
	</div>
</template>

<script>

	import axios from 'axios'
	import storeMixin from '@/mixins/store-mixin';
	import { ValidationObserver, ValidationProvider } from 'vee-validate'
	import { BModal, VBModal, BButton } from 'bootstrap-vue'
	import { bannerStatus } from '@/mixins/banner-status-mixin';

	export default {
		name: 'ChangePassword',
		mixins: [ storeMixin, bannerStatus ],
		components: {
			'b-modal': BModal,
			'b-button': BButton,
			ValidationObserver,
			ValidationProvider

		},
		directives: {
			'b-modal': VBModal
		},
		data() {
			return {
				alertShow: false,
				isSuccess: false,
				isDanger: false,
				alertMessage: '',
				password: '',
				passwordCheck: '',
				responseVariant: '',
				goToLogin: false
			}
		},
		destroyed() {
			this.logout()
		},
		methods: {
			formIsValid() {
				this.submit()
			},
			submit() {
				let that = this;
				let changePasswordOptions = {
					url: '/dp/api/change-password',
					method: 'POST',
					data: {
						'changePasswordUUID': that.$route.query[ 'uuid' ],
						'pw': that.password
					}
				};
				axios.request( changePasswordOptions )
					.then( response => {
						// console.log( JSON.stringify( response ) )
						let success = response.data[ 'success' ];
						if ( success ) {
							that.alertShow = true;
							that.responseVariant = 'success';
							that.alertMessage = response.data[ 'message' ]
							that.goToLogin = true;
						} else {
							that.alertShow = true;
							that.responseVariant = 'danger';
							that.alertMessage = response.data[ 'message' ]
							that.goToLogin = false;
						}
					} )
					.catch( error => {
						console.error( 'Error: ' + JSON.stringify( error ) );

					} )
			},
			clearAlert( bvModalEvt ) {
				this.alertMessage = '';
				this.responseVariant = '';
				this.alertShow = false;
				if ( this.goToLogin ) {
					this.$router.push( { name: 'login' } )
				}
			}
		},

	}
</script>

<style scoped>

</style>
