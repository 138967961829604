<template>
	<div>
		<section class="slideshow featured">
			<article>
				<h1 class="text-white strong">Contact</h1>
			</article>
			<img src="../assets/featured-image-sec_contact.jpg" width="1920" height="361" alt="Contact">
		</section>
		<div class="banner">
			<b-modal id="modal-center" centered v-model="alertShow"
					:body-bg-variant="responseVariant"
					:header-bg-variant="responseVariant"
					:footer-bg-variant="responseVariant"
					:no-close-on-backdrop="true"
					:no-close-on-esc="true"
					@ok="clearAlert"
			>
				<p class="my-4">{{alertMessage}}</p>
			</b-modal>
			<h4>
				{{ this.status }}
			</h4>
		</div>
		<main>
			<article class="contents">
				<div class="container">
					<div class="row">
						<div class="col-md-8 mb-10">
							<form class="donation-form">
								<div class="form-pickup">
									<b-form-group class="form-group" label="Name">
										<input type="text" class="form-control" id="name" v-model="name" required>
									</b-form-group>
								</div>
								<div class="form-pickup">
									<b-form-group class="form-group" label="Email">
										<input type="email" class="form-control" id="email" aria-describedby="emailHelp" v-model="email"
												required>
										<small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone
											else.</small>
									</b-form-group>
								</div>
								<div class="form-pickup">
									<b-form-group class="form-group" label="Address">
										<input type="text" class="form-control" id="address" v-model="address">
									</b-form-group>
								</div>
								<div class="autocomplete-results" v-if="isAutocomplete">
									<ul>
										<li v-for="suggestedAddress in this.autocompleteResults"
												@click.prevent="setSelected(suggestedAddress)">
											{{ suggestedAddress.text }}
										</li>
									</ul>
								</div>
								<div class="row donation-inline">
									<div class="col col-md-7 no-padding-right">
										<div class="form-pickup">
											<b-form-group class="form-group" label="City">
												<input type="text" class="form-control" id="city" v-model="city">
											</b-form-group>
										</div>
									</div>
									<div class="col col-md-2 no-padding-sides">
										<div class="form-pickup">
											<b-form-group class="form-group" label="State">
												<div class="form-group donor-info-select">
													<BFormSelect v-model="state" :options="stateOptions" id="State"></BFormSelect>
												</div>
											</b-form-group>
										</div>
									</div>
									<div class="col col-md-3 no-padding-left">
										<div class="form-pickup">
											<b-form-group class="form-group" label="Zip">
												<input type="text" class="form-control" id="zip" v-model="zip">
											</b-form-group>
										</div>
									</div>
								</div>
								<div class="form-pickup">
									<b-form-group class="form-group" label="Phone">
										<input type="text" class="form-control" id="phone" v-model="phone">
									</b-form-group>
								</div>
								<div class="form-pickup">
									<b-form-group class="form-group" label="Comments">
										<textarea class="form-control" id="comments" rows="3" v-model="comments"></textarea>
									</b-form-group>
								</div>
								<button type="submit" class="btn btn-primary" @click.prevent="recaptcha">Send Message</button>
							</form>
						</div>
						<div class="contact-info col-md-4">
							<h4>Register for eNotice</h4>
							<p>If you prefer emails, ​
								<router-link to="/register">register for eNotice​</router-link>
								. You will receive emails
								notifying you when we are going to be in your area.
							</p>
							<h4>Register for Text Message</h4>
							<p>Provide your mobile phone number, and you will also receive a text message when we plan to be in your
								area.</p>
							<h4>If you have any questions, comments, or concerns, please contact us.</h4>
							<p>
								<router-link to="/faq">Review our ​FAQ Page​.</router-link>
							</p>
							<p><strong>Email Us:</strong> <br><a
									href="mailto:donorservices@donationpickup.org">donorservices@donationpickup.org</a></p>
							<p><strong>Call Us:</strong><br> 866-OKPICKUP • 866-657-4258</p>

						</div>
					</div>
				</div>
			</article>
		</main>

	</div>
</template>

<script>
	import { VueReCaptcha } from 'vue-recaptcha-v3'
	import { BModal, VBModal, BButton, BFormSelect } from 'bootstrap-vue'

	import axios from 'axios'

	import { bannerStatus } from '@/mixins/banner-status-mixin';


	export default {
		name: 'Contact',
		components: {
			BFormSelect,
			'b-modal': BModal,
			'b-button': BButton
		},
		directives: {
			'b-modal': VBModal
		},
		mixins: [ bannerStatus ],
		data() {
			return {
				suggestionIsSelected: false,
				stateOptions: [
					{ value: null, text: 'Choose State' },
					{ value: 'AK', text: 'AK' },
					{ value: 'AL', text: 'AL' },
					{ value: 'AR', text: 'AR' },
					{ value: 'AZ', text: 'AZ' },
					{ value: 'CA', text: 'CA' },
					{ value: 'CO', text: 'CO' },
					{ value: 'CT', text: 'CT' },
					{ value: 'DE', text: 'DE' },
					{ value: 'FL', text: 'FL' },
					{ value: 'GA', text: 'GA' },
					{ value: 'HI', text: 'HI' },
					{ value: 'IA', text: 'IA' },
					{ value: 'ID', text: 'ID' },
					{ value: 'IL', text: 'IL' },
					{ value: 'IN', text: 'IN' },
					{ value: 'KS', text: 'KS' },
					{ value: 'KY', text: 'KY' },
					{ value: 'LA', text: 'LA' },
					{ value: 'MA', text: 'MA' },
					{ value: 'MD', text: 'MD' },
					{ value: 'ME', text: 'ME' },
					{ value: 'MI', text: 'MI' },
					{ value: 'MN', text: 'MN' },
					{ value: 'MO', text: 'MO' },
					{ value: 'MS', text: 'MS' },
					{ value: 'MT', text: 'MT' },
					{ value: 'NC', text: 'NC' },
					{ value: 'ND', text: 'ND' },
					{ value: 'NE', text: 'NE' },
					{ value: 'NH', text: 'NH' },
					{ value: 'NJ', text: 'NJ' },
					{ value: 'NM', text: 'NM' },
					{ value: 'NV', text: 'NV' },
					{ value: 'NY', text: 'NY' },
					{ value: 'OH', text: 'OH' },
					{ value: 'OK', text: 'OK' },
					{ value: 'OR', text: 'OR' },
					{ value: 'PA', text: 'PA' },
					{ value: 'RI', text: 'RI' },
					{ value: 'SC', text: 'SC' },
					{ value: 'SD', text: 'SD' },
					{ value: 'TN', text: 'TN' },
					{ value: 'TX', text: 'TX' },
					{ value: 'UT', text: 'UT' },
					{ value: 'VA', text: 'VA' },
					{ value: 'VT', text: 'VT' },
					{ value: 'WA', text: 'WA' },
					{ value: 'WI', text: 'WI' },
					{ value: 'WV', text: 'WV' },
					{ value: 'WY', text: 'WY' }
				],
				email: '',
				phone: '',
				address: '',
				city: '',
				state: null,
				zip: '',
				comments: '',
				name: '',
				alertShow: false,
				isSuccess: false,
				isDanger: false,
				alertMessage: '',
				autocompleteResults: [],
				debounceMilliseconds: 50,
				isAutocomplete: false,
				addressZip: '',
				selectedAddress: '',
				responseVariant: ''
			}
		},
		watch: {
			address: function () {
				if ( !this.suggestionIsSelected ) {
					this.autocomplete()
				}
			}
		},
		methods: {
			autocomplete() {
				let that = this;

				let st = that.address;

				clearTimeout( that.timeout );
				// let smartyUrl = 'https://us-autocomplete.api.smartystreets.com/suggest?key=14842323673431934&prefix=' + st.split( ' ' ).join( '+' ) + '&state_filter=ok';
				let smartyUrl = 'https://us-autocomplete-pro.api.smartystreets.com/lookup?key=14842323673431934&search=' + st.split( ' ' ).join( '+' );
				that.timeout = setTimeout( () => {
					const smartPromise = axios.get( smartyUrl );
					Promise.all( [ smartPromise ] ).then( value => {
						let smartyData = value[ 0 ].data;
						// console.log( JSON.stringify( smartyData ) );
						if ( value[ 0 ].data[ 'suggestions' ] !== null ) {
							that.buildAddress( smartyData[ 'suggestions' ] );
							let suggestionOptions = {};
							let suggestionsCount = smartyData[ 'suggestions' ].length;
							that.autocompleteResults = smartyData[ 'suggestions' ];
							that.isAutocomplete = true;
						}
					} ).catch( error => {
						console.log( error )
					} )
				}, that.debounceMilliseconds );
			},
			setSelected( text ) {  // {"street_line":"8101 S Anderson Rd","secondary":"","city":"Oklahoma City","state":"OK","zipcode":"73150","entries":0}
				console.log( text );
				this.address = text[ 'street_line' ];
				this.city = text.city;
				this.state = text.state;
				this.selectedAddress = text;
				this.addressZip = text[ 'zipcode' ];
				this.zip = text[ 'zipcode' ];
				this.suggestionIsSelected = !this.suggestionIsSelected;
				this.isAutocomplete = false;
			},
			buildAddress( suggestions ) {
				let tmpArr = [];
				for ( const suggestion of suggestions ) {
					let whiteSpace = '';
					if ( suggestion.secondary ) {
						if ( suggestion.entries > 1 ) {
							suggestion.secondary += ' (' + suggestion.entries + ' entries)';
						}
						whiteSpace = ' ';
					}
					suggestion.text = suggestion[ 'street_line' ] + whiteSpace + suggestion.secondary + ' ' + suggestion.city + ', ' + suggestion.state + ', ' + suggestion[ 'zipcode' ];
					suggestion.zip = suggestion[ 'zipcode' ];
					suggestion.city = suggestion[ 'city' ];
					suggestion.state = suggestion[ 'state' ];
					suggestion.value = suggestion[ 'street_line' ] + whiteSpace + suggestion.secondary + ' ' + suggestion.city + ', ' + suggestion.state + ', ' + suggestion[ 'zipcode' ];
					tmpArr.push( suggestion )
				}
				return tmpArr
			},
			formIsValid() {
				this.submit();
			},
			async recaptcha() {
				let that = this;
				await this.$recaptchaLoaded();

				const token = await this.$recaptcha( 'contact' );

				console.log( token );
				let recaptchaOptions = {
					url: '/dp/api/recaptcha',
					method: 'POST',
					data: {
						token: token,
						contact: {
							name: that.name,
							email: that.email,
							address: that.address,
							city: that.city,
							state: that.state,
							phone: that.phone,
							zip: that.zip,
							comments: that.comments
						}
					}
				};
				axios.request( recaptchaOptions )
					.then( response => {
						console.log( JSON.stringify( response ) );
						let success = response.data[ 'success' ];
						if ( success ) {
							that.alertShow = true;
							that.responseVariant = 'success';
							that.alertMessage = response.data[ 'message' ];
							that.resetForm();
						} else {
							that.alertShow = true;
							that.responseVariant = 'danger';
							that.alertMessage = response.data[ 'message' ]
						}
					} )
					.catch( error => {
						console.error( 'Error: ' + JSON.stringify( error ) );

					} )
			},
			submit() {
				// this.recaptcha();
				let that = this;
				let contactFormOptions = {
					url: '/dp/api/contact-form',
					method: 'POST',
					data: {
						name: that.name,
						email: that.email,
						address: that.address,
						city: that.city,
						state: that.state,
						phone: that.phone,
						zip: that.zip,
						comments: that.comments
					}
				};
				axios.request( contactFormOptions )
					.then( response => {
						console.log( JSON.stringify( response ) );
						let success = response.data[ 'success' ];
						if ( success ) {
							that.alertShow = true;
							that.responseVariant = 'success';
							that.alertMessage = response.data[ 'message' ]
							that.resetForm();
						} else {
							that.alertShow = true;
							that.responseVariant = 'danger';
							that.alertMessage = response.data[ 'message' ]
						}
					} )
					.catch( error => {
						console.error( 'Error: ' + JSON.stringify( error ) );

					} )
			},
			clearAlert() {
				this.alertMessage = '';
				this.alertShow = false;
				this.isSuccess = false;
				this.isDanger = false;
			},
			resetForm() {
				this.email = '';
				this.phone = '';
				this.address = '';
				this.city = '';
				this.state = '';
				this.zip = '';
				this.comments = '';
				this.name = '';
			}
		},
	}
</script>

<style scoped>

	@media screen and (max-width: 580px) {
		.margin-bottom {
			margin-bottom: 25px;
		}
	}

	.contents select {
		padding: 0px 10px;
	}

</style>
