import axios from 'axios';

export const bannerStatus = {
	created() {
		this.getStatus()
	},
	data() {
		return {
			status: ''
		}
	},
	methods: {
		getStatus() {
			let that = this;
			let statusOptions = {
				url: '/dp/api/get-status',
				method: 'GET',
				data: {}
			};
			axios.request( statusOptions )
				.then( response => {
					that.status = response.data['statusMessage']
				} )
				.catch( error => {
					console.error( 'Error: ' + JSON.stringify( error ) );
					that.status	= ''
				} )
		}
	}
};
