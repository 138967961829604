<template>
	<div>
		<section class="slideshow featured">
			<article>
				<h1 class="text-white strong">Donor Information</h1>
			</article>
			<img src="../assets/featured-image-sec_donations.jpg" width="1920" height="361" alt="Donor Information">
		</section>
		<div class="banner">
			<b-modal id="modal-center" centered v-model="alertShow"
					:body-bg-variant="responseVariant"
					:header-bg-variant="responseVariant"
					:footer-bg-variant="responseVariant"
					:no-close-on-backdrop="true"
					:no-close-on-esc="true"
					@ok="clearAlert"
			>
				<p class="my-4">{{alertMessage}}</p>
			</b-modal>
			<h4>
				{{ this.status }}
			</h4>
		</div>
		<main>
			<article class="contents">
				<div class="container">

					<ValidationObserver v-slot="{handleSubmit}">
						<b-form class="donation-form" @submit.prevent="handleSubmit(formIsValid)">
							<div class="row">
								<div class="col-md">

									<div class="form-pickup">
										<ValidationProvider rules="required" v-slot="{valid,errors}" name="First Name">
											<b-form-group label="First Name">
												<b-form-input v-model="firstName"
														:state="errors[0] ? false : (valid ? true : null)">
												</b-form-input>
												<b-form-invalid-feedback id="inputLiveFeedback">{{ errors[0] }}</b-form-invalid-feedback>
											</b-form-group>
										</ValidationProvider>
									</div>

									<div class="form-pickup">
										<ValidationProvider rules="required" v-slot="{valid, errors}" name="Last Name">
											<b-form-group label="Last Name">
												<b-form-input v-model="lastName"
														:state="errors[0] ? false : (valid ? true : null)">
												</b-form-input>
												<b-form-invalid-feedback id="inputLiveFeedback">{{ errors[0] }}</b-form-invalid-feedback>
											</b-form-group>
										</ValidationProvider>
									</div>
									<div class="form-pickup">
										<ValidationProvider rules="required|email" v-slot="{valid, errors}" name="Email">
											<b-form-group label="Email" description="We'll never share your email with anyone else.">
												<b-form-input type="email" v-model="email"
														:state="errors[0] ? false : (valid ? true : null)">
												</b-form-input>
												<b-form-invalid-feedback id="inputLiveFeedback">{{ errors[0] }}</b-form-invalid-feedback>
											</b-form-group>
										</ValidationProvider>
									</div>
									<div class="form-pickup">
										<ValidationProvider rules="required" v-slot="{valid, errors}" name="Phone">
											<b-form-group label="Mobile Telephone" description="No Dashes">
												<b-form-input type="text" v-model="phone"
														:state="errors[0] ? false : (valid ? true : null)">
												</b-form-input>
												<b-form-invalid-feedback id="inputLiveFeedback">{{ errors[0] }}</b-form-invalid-feedback>
											</b-form-group>
										</ValidationProvider>
									</div>
									<div class="form-pickup">
										<div class="form-group">
											<label for="gateCode">Gate Code</label>
											<input type="text" class="form-control" id="gateCode" v-model="gateCode">
										</div>
									</div>
									<div class="form-pickup">
										<div class="form-group donor-info-select">
											<label for="securityQuestion">Security Question</label>
											<BFormSelect v-model="securityQuestion" :options="securityQuestionOptions"
													id="securityQuestion"></BFormSelect>
										</div>
									</div>
									<div class="form-pickup">
										<div class="form-group">
											<label for="securityAnswer">Security Answer</label>
											<input type="text" class="form-control" id="securityAnswer" v-model="securityAnswer">
										</div>
									</div>


								</div>
								<div class="col-md">

									<div class="form-pickup">
										<div class="form-group form-check">
											<input type="checkbox" class="form-check-input" id="eNotices" v-model="eNotices">
											<label class="form-check-label" for="eNotices"><span
													class="form-bold">Receive eNotices?</span></label>
										</div>
									</div>
									<div class="form-pickup">
										<div class="row">
											<div class="col-md">
												<div class="form-group form-check">
													<input type="checkbox" class="form-check-input" id="sms" v-model="sms">
													<label class="form-check-label" for="sms"><span
															class="form-bold">Receive SMS text messages?</span></label>
												</div>
											</div>
										</div>
										<div class="col-md">
											<div v-if="sms" class="donor-info-select">
												<ValidationProvider rules="required" v-slot="{valid, errors}" name="Mobile Carrier">
													<b-form-group label="Mobile Carrier">
														<BFormSelect v-model="carrier" :options="carrierOptions"
																id="carrier"
																:state="errors[0] ? false : (valid ? true : null)">
														</BFormSelect>
														<b-form-invalid-feedback id="inputLiveFeedback">{{ errors[0] }}
														</b-form-invalid-feedback>
													</b-form-group>
												</ValidationProvider>
											</div>
										</div>
									</div>
									<div class="form-pickup-notice">
										If you prefer emails or SMS text messages rather than telemarketing calls, click to sign up for
										eNotice
										and SMS text messages. Receive emails and text messages notifying you when we are going to be in
										your
										area. Just check the box and click on Update Information in the lower left corner of the page.
									</div>
									<div>
										<div class="mt-5">

											<div class="form-pickup-notice">Your current address for donations is:</div>
											<div class="current-address">{{ currentAddress }}</div>
											<div class="text-center">
												<b-button v-b-toggle.collapse-1 variant="outline-primary">Have you moved?</b-button>
											</div>
										</div>
										<b-collapse id="collapse-1" class="mt-2">
											<b-card>
												<p class="card-text">If you have moved from {{ currentAddress }} and would like to update your address, please confirm the
													disclosure to proceed.</p>
												<p class="font-italic">By choosing to update your address your information will be removed from
													the current address
													and you will be prompted to re-register using the new address. Please print any previous
													donations from this address before proceeding. You can access previous donations
													<router-link to="/review-past-donations">here.</router-link>
												</p>
												<div class="form-group form-check">
													<input type="checkbox" class="form-check-input" id="confirmDisclosure"
															v-model="confirmDisclosure">
													<label class="form-check-label" for="confirmDisclosure"><span
															class="form-bold">Are you sure you want to proceed?</span></label>
												</div>
												<div v-if="confirmDisclosure">
													<b-button @click.prevent="clearDonorInfo" variant="light">Remove Address</b-button>
												</div>
											</b-card>
										</b-collapse>
									</div>
								</div>
							</div>
							<div class="text-left">
								<button type="submit" class="btn btn-primary">Update Information</button>
							</div>
						</b-form>
					</ValidationObserver>
				</div>
			</article>
		</main>
	</div>
</template>

<script>
	import Cookies from 'js-cookie'
	import axios from 'axios'
	import { BFormSelect, BModal, VBModal, BButton } from 'bootstrap-vue'
	import storeMixin from '@/mixins/store-mixin';
	import { ValidationObserver, ValidationProvider } from 'vee-validate';
	import { bannerStatus } from '@/mixins/banner-status-mixin';

	export default {
		name: 'donor-information',
		components: {
			BFormSelect,
			'b-modal': BModal,
			'b-button': BButton,
			ValidationProvider,
			ValidationObserver

		},
		directives: {
			'b-modal': VBModal
		},
		mixins: [ storeMixin, bannerStatus ],
		data() {
			return {
				confirmDisclosure: false,
				firstName: '',
				clearDonor: false,
				currentAddress: '',
				lastName: '',
				email: '',
				phone: '',
				gateCode: '',
				securityQuestion: null,
				securityAnswer: '',
				sms: false,
				eNotices: false,
				alertShow: false,
				isSuccess: false,
				isDanger: false,
				carrier: null,
				carrierOptions: [
					{ value: null, text: 'Please select mobile carrier' },
					{ value: 'ATT', text: 'AT&T' },
					{ value: 'Alltel', text: 'Alltel' },
					{ value: 'Boost Mobile', text: 'Boost Mobile' },
					{ value: 'Cricket', text: 'Cricket' },
					{ value: 'GIV Mobile', text: 'GIV Mobile' },
					{ value: 'Google Fi', text: 'Google Fi' },
					{ value: 'Sprint', text: 'Sprint' },
					{ value: 'MetroPCS', text: 'MetroPCS' },
					{ value: 'Republic', text: 'Republic' },
					{ value: 'Straight Talk', text: 'Straight Talk' },
					{ value: 'T-Mobile', text: 'T-Mobile' },
					{ value: 'Ting', text: 'Ting' },
					{ value: 'US Cellular', text: 'US Cellular' },
					{ value: 'Verizon', text: 'Verizon' },
					{ value: 'Virgin Mobile', text: 'Virgin Mobile' },
					{ value: 'Xfinity', text: 'Xfinity' }
				],
				alertMessage: '',
				securityQuestionOptions: [
					{ value: null, text: 'Please select a security question' },
					{ value: 'What was your childhood nickname?', text: 'What was your childhood nickname?' },
					{
						value: 'What is your maternal grandmother\'s maiden name?',
						text: 'What is your maternal grandmother\'s maiden name?'
					},
					{
						value: 'What city did you meet your spouse/significant other?',
						text: 'What city did you meet your spouse/significant other?'
					},
					{ value: 'What is your oldest sibling\'s middle name?', text: 'What is your oldest sibling\'s middle name?' }
				],
				responseVariant: ''
			}
		},
		created: function () {
			let that = this;
			let donorOptions = {
				method: 'POST',
				url: '/dp/api/get-donor-info',
				data: {
					donorID: that.getDonorID
				}
			};
			axios.request( donorOptions )
				.then( response => {
					// console.log( JSON.stringify( response ) );
					that.sms = response.data[ 'sms' ];
					that.eNotices = response.data[ 'eNotices' ];
					that.firstName = response.data[ 'firstName' ];
					that.lastName = response.data[ 'lastName' ];
					that.phone = response.data[ 'phone' ];
					that.email = response.data[ 'email' ];
					that.gateCode = response.data[ 'gateCode' ];
					that.securityQuestion = response.data[ 'securityQuestion' ];
					that.securityAnswer = response.data[ 'securityAnswer' ];
					that.carrier = response.data[ 'carrier' ];
					that.currentAddress = response.data[ 'currentAddress' ];
				} )
				.catch( error => {
					console.log( 'Error: ' + JSON.stringify( error ) );

				} )
		},
		methods: {
			formIsValid() {
				// console.log( this )
				this.submit()
			},
			submit() {
				let that = this;
				let escaped = that.phone.replace( /\D+/g, '' );
				let updateOptions = {
					method: 'POST',
					url: '/dp/api/update-donor-info',
					data: {
						sms: that.sms,
						eNotices: that.eNotices,
						phone: escaped,
						firstName: that.firstName,
						lastName: that.lastName,
						email: that.email,
						gateCode: that.gateCode,
						securityQuestion: that.securityQuestion,
						securityAnswer: that.securityAnswer,
						donorID: that.getDonorID,
						carrier: that.carrier
					}
				};
				axios.request( updateOptions )
					.then( response => {
						// console.log( JSON.stringify( response ) )
						let success = response.data[ 'success' ];
						if ( success ) {
							that.alertShow = true;
							that.responseVariant = 'success';
							that.alertMessage = response.data[ 'message' ]
						} else {
							that.alertShow = true;
							that.responseVariant = 'danger';
							that.alertMessage = response.data[ 'message' ]
						}
					} )
					.catch( error => {
						console.error( 'Error: ' + JSON.stringify( error ) );

					} )
			},
			changePassword() {
				let that = this;
				let changePasswordRequestOptions = {
					url: '/dp/api/change-password-request',
					method: 'POST',
					data: {
						donorID: Cookies.get( 'donorID' )
					}
				};
				axios.request( changePasswordRequestOptions )
					.then( response => {
						// console.log( JSON.stringify( response ) );
						let success = response.data[ 'success' ];
						if ( success ) {
							that.alertShow = true;
							that.responseVariant = 'success';
							that.alertMessage = response.data[ 'message' ]
						} else {
							that.alertShow = true;
							that.responseVariant = 'danger';
							that.alertMessage = response.data[ 'message' ]
						}
					} )
					.catch( error => {
						console.error( 'Error: ' + JSON.stringify( error ) );

					} )
			},
			clearDonorInfo() {
				let that = this;
				let clearOptions = {
					url: '/dp/api/clear-donor-info',
					method: 'POST',
					data: {
						donorID: that.getDonorID
					}
				};
				axios.request( clearOptions )
					.then( response => {
						console.log( JSON.stringify( response ) );
						let success = response.data[ 'success' ];
						if ( success ) {
							that.alertShow = true;
							that.responseVariant = 'success';
							that.alertMessage = response.data[ 'message' ];
							that.clearDonor = true;
							that.logout()
						}
					} )
					.catch( error => {
						console.error( 'Error: ' + JSON.stringify( error ) );

					} )
			},
			clearAlert( bvModalEvt ) {
				this.alertMessage = '';
				this.responseVariant = '';
				this.alertShow = false;
				if ( this.clearDonor ) {
					this.logout();
					this.$router.push( { name: 'register' } )
				}
			}
		}
	}
</script>

<style scoped>
	.contents .donor-info-select select {
		padding-top: 0;
		padding-bottom: 0;
		color: #000;
	}
</style>
