<template>
	<div>
		<section class="slideshow featured">
			<article>
				<h1 class="text-white strong">Acceptable Items</h1>
			</article>
			<img src="../assets/featured-image-sec_acceptable-items.jpg" width="1920" height="361" alt="Acceptable Items">
		</section>
		<div class="banner">
			<h4>
				{{ this.status }}
			</h4>
		</div>
		<main>
			<article class="contents">
				<div class="container">
					<div class="row">
						<div class="col-md">
							<h3>We Need Usable</h3>
							<ul>
								<li>Clothing (Men, Women, Children, Infant)</li>
								<li>Shoes, Boots, Belts, Purses and Accessories</li>
								<li>Furniture, Rugs, and Lamps</li>
								<li>Electronics</li>
								<li>Kitchen & Housewares</li>
								<li>Televisions (except console)</li>
								<li>Small Electrical Appliances</li>
								<li>Computers (XP, or better, flat screen)</li>
								<li>Bicycles, Mowers, and Lawn Items</li>
								<li>Bedding, Pillows, Drapes, and Towels</li>
								<li>Toys, Games, and Sporting Goods</li>
								<li>Books, Music, and Movies</li>
								<li>Knick-Knacks and Seasonal Items</li>
								<li>Pictures, Frames, and Decorations</li>
								<li>Art, Jewelry, and Antiques</li>
								<li>Just About any Usable Household Item</li>
							</ul>
						</div>
						<div class="col-md">
							<h3>We Cannot Accept</h3>
							<ul>
								<li>Furniture in need of repair</li>
								<li>Metal Desks or Exercise Equipment</li>
								<li>Washers, Dryers, Refrigerators, or Freezers</li>
								<li>Dishwashers, Ovens, or Ranges</li>
								<li>Mattresses, Box Springs, or Water Beds</li>
								<li>Hot Water Tanks or Central Heat/Air Units</li>
								<li>Garage Door Openers or Building Materials</li>
								<li>Basketball Goals or Ping-Pong Tables</li>
								<li>Swing Sets or Trampolines (full size)</li>
								<li>Cribs, or Infant's or Children's Car Seats</li>
								<li>Infant Carrier Items (anything that holds a baby)</li>
								<li>Computers (older than Windows XP)</li>
								<li>CRT Monitors or non-Laser Printers</li>
								<li>Pianos, Console or projection TVs</li>
								<li>Hangers, Paper Sacks, or Newspapers</li>
								<li>Hazardous Items (paint, fuel, oil, tires)</li>
							</ul>
						</div>
					</div>

				</div>
				<p class="text-center"><strong>This list is not all-inclusive.</strong><br/><router-link to="/contact">Contact Us</router-link>
					for questions about specific items.</p>
			</article>
		</main>
	</div>
</template>

<script>
	import { bannerStatus } from '@/mixins/banner-status-mixin';
	export default {
		name: 'AcceptableItems',
		mixins: [bannerStatus]
	}
</script>

<style scoped>
	.contents ul li::before {
		top: 10px;
	}
</style>
