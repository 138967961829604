<template>
	<div>
		<section class="slideshow featured">
			<article>
				<h1 class="text-white strong">Login</h1>
			</article>
			<img src="../assets/featured-image-sec_login.jpg"
					width="1920"
					height="361" alt="Login">
		</section>
		<div class="banner">
			<b-modal id="modal-center" centered v-model="alertShow"
					:body-bg-variant="responseVariant"
					:header-bg-variant="responseVariant"
					:footer-bg-variant="responseVariant"
					:no-close-on-backdrop="true"
					:no-close-on-esc="true"
					@ok="clearAlert">
				<p class="my-4">{{alertMessage}}</p>
			</b-modal>
			<h4>
				{{ this.status }}
			</h4>
		</div>
		<div class="reset">
			<b-modal id="reset-password" centered v-model="resetShow"
			>
				<b-form>
					<b-form-group label="Please enter your email address"
							label-for="email"
							description="We'll never share your email with anyone else.">
						<b-form-input
								type="email"
								v-model="username"
						></b-form-input>
					</b-form-group>
					<p v-if="emailNotValid" class="text-danger">Please enter a valid email address in order to submit your request
						for a new password</p>
				</b-form>
				<template v-slot:modal-footer>
					<div class="w-100">
						<div class="row">
							<div class="col-md-6">
								Please enter your email address and then click Reset Password
							</div>
							<div class="col-md-6">
								<b-button
										variant="primary"
										size="sm"
										class="float-right"
										@click="canSubmitReset">
									Reset Password
								</b-button>
							</div>
						</div>


					</div>
				</template>
			</b-modal>
		</div>
		<main>
			<article class="contents">
				<div class="container">
					<div class="row">
						<div class="col-md">
							<h4>Login</h4>
							<ValidationObserver v-slot="{ handleSubmit }">
								<b-form @submit.prevent="handleSubmit(formIsValid)" class="donation-form">
									<div class="form-pickup">
										<ValidationProvider rules="required|email" name="Email" v-slot="{ valid, errors }">
											<b-form-group label="Email"
													label-for="email"
													description="We'll never share your email with anyone else.">
												<b-form-input
														type="email"
														v-model="username"
														:state="errors[0] ? false : (valid ? true : null)"
												></b-form-input>
												<b-form-invalid-feedback id="inputLiveFeedback">
													{{ errors[0] }}
												</b-form-invalid-feedback>
											</b-form-group>
										</ValidationProvider>
										<div class="form-pickup small-margin-bottom">
											<ValidationProvider
													rules="required"
													name="Password"
													v-slot="{valid, errors }"
											>
												<b-form-group
														label="Password"
														label-for="password">
													<b-form-input
															type="password"
															v-model="password"
															:state="errors[0] ? false : (valid ? true : null)"
													></b-form-input>
												</b-form-group>
												<b-form-invalid-feedback id="inputLiveFeedback">{{ errors[0] }}</b-form-invalid-feedback>
											</ValidationProvider>

										</div>

									</div>
									<div class="row">
										<div class="col-md">

											<div class="text-left">
												<button type="submit" class="btn btn-primary">Login</button>
											</div>
										</div>
										<div class="col-md">

											<div class="text-right">
												<div class="btn-link reset-button" @click.prevent="canResetPassword">Need to Reset Password?
												</div>
											</div>
										</div>
									</div>

								</b-form>
							</ValidationObserver>
						</div>
						<div class="col-md small-margin-top">
							<h4>Register Now</h4>
							<p>
								<router-link to="/register">If you aren't already a registered member, Register here</router-link>
							</p>
						</div>
					</div>
				</div>
			</article>
		</main>
	</div>
</template>

<script>

	import axios from 'axios';
	import { ValidationProvider, ValidationObserver } from 'vee-validate';
	import storeMixin from '@/mixins/store-mixin';
	import { bannerStatus } from '@/mixins/banner-status-mixin';
	import XRegExp from 'xregexp'


	export default {
		name: 'Login',
		components: {
			ValidationProvider,
			ValidationObserver
		},
		mixins: [ storeMixin, bannerStatus ],
		data() {
			return {
				loginIsBusy: false,
				username: '',
				password: '',
				useForm: true,
				alertShow: false,
				alertMessage: '',
				isSuccess: false,
				isDanger: false,
				okDisabled: true,
				responseVariant: '',
				resetShow: false,
				emailNotValid: false

			}
		},
		created: function () {

		},
		mounted() {

		},
		methods: {
			formIsValid() {
				this.submit()
			},
			resetModal( bvModalEvt ) {
				this.alertMessage = '';
				this.responseVariant = '';
				this.alertShow = false;
			},
			showModal() {
				this.showModal = true;
			},
			canSubmitReset() {
				const checkEmail = XRegExp( '[^@ \\t\\r\\n]+@[^@ \\t\\r\\n]+\\.[^@ \\t\\r\\n]+' );
				let isEmail = checkEmail.test( this.username );
				if ( isEmail ) {
					this.resetPassword()
					this.emailNotValid = !this.emailNotValid;
				} else {
					this.emailNotValid = !this.emailNotValid;
				}
			},
			submit() {
				let that = this;
				let loginOptions = {
					url: '/dp/api/login',
					method: 'POST',
					data: {
						username: that.username,
						pw: that.password
					}
				};
				axios.request( loginOptions ).then( response => {
					// console.log( JSON.stringify( response.data ) );
					let pickupExists = response.data[ 'pickupExists' ];
					let changePassword = response.data[ 'mustChangePassword' ];
					let success = response.data[ 'success' ];
					if ( success ) {
						response.data.loggedIn = true;
						response.data.username = that.username;
						if ( pickupExists ) {
							that.login( response.data );
							that.$router.push( { name: 'modify-cancel-donation' } );
						} else {
							that.login( response.data );
							that.$router.push( { name: 'donations' } );
						}
					} else if ( changePassword ) {
						that.login( response.data );
						that.$router.push( { name: 'change-password' } );
					} else {
						that.alertShow = true;
						that.responseVariant = 'danger';
						that.alertMessage = response.data[ 'message' ];
					}
				} ).catch( error => {
					console.error( JSON.stringify( error ) )
				} )
			},
			clearAlert( bvModalEvt ) {
				this.alertMessage = '';
				this.responseVariant = '';
				this.alertShow = false;
			},
			canResetPassword() {
				this.resetShow = true;
			},
			resetPassword() {
				let that = this;
				this.resetShow = false;
				let changePasswordRequestOptions = {
					url: '/dp/api/change-password-request',
					method: 'POST',
					data: {
						email: that.username,
					}
				};
				axios.request( changePasswordRequestOptions )
					.then( response => {
						// console.log( JSON.stringify( response ) );
						let success = response.data[ 'success' ];
						if ( success ) {
							that.alertShow = true;
							that.responseVariant = 'success';
							that.alertMessage = response.data[ 'message' ]
						} else {
							that.alertShow = true;
							that.responseVariant = 'danger';
							that.alertMessage = response.data[ 'message' ]
						}
					} )
					.catch( error => {
						console.error( 'Error: ' + JSON.stringify( error ) );

					} )
			}
		}
	}
</script>

<style>
	.small-margin-bottom {
		margin-bottom: 10px;
	}

	@media screen and (max-width: 580px) {
		.col-md.small-margin-top {
			margin-top: 25px;
		}

	}

	.btn-link.reset-button {
		line-height: 46px;
		color: #00b8f4;
	}

</style>
