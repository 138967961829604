<template>
	<div>
		<section class="slideshow featured donation-bottom">
			<article>
				<h1 class="text-white strong">Schedule a New Pickup</h1>
			</article>
			<img src="../assets/featured-image-sec_donations.jpg"
					width="1920" height="361" alt="Schedule a New Pickup">
		</section>
		<div class="banner">
			<b-modal id="modal-center" centered v-model="alertShow" :class="{'pickup-exists' : showPickupExists }"
					:body-bg-variant="responseVariant"
					:header-bg-variant="responseVariant"
					:footer-bg-variant="responseVariant"
					:no-close-on-backdrop="true"
					:no-close-on-esc="true"
					@ok="clearAlert"
			>
				<div v-if="showPickupExists">
					<div class="pickup-exists">
						<h5 class="my-4">{{alertMessage}}</h5><br>
						<div class="row show-pickup-modal">
							<div class="col-md">

								<b-button type="button" class="btn btn-primary" @click.prevent="modifyAndCancel">Modify Pickup
								</b-button>
							</div>
							<div class="col-md">
								<b-button type="button" class="btn btn-primary" @click.prevent="cancelPickup">Cancel Pickup</b-button>
							</div>
						</div>
					</div>


				</div>
				<div v-else>

					<p class="my-4">{{alertMessage}}</p>
				</div>
			</b-modal>
			<h4>
				{{ this.status }}
			</h4>
		</div>
		<main>
			<div class="container">
				<div class="row">
					<div class="col-md">
						<ValidationObserver v-slot="{handleSubmit}">
							<b-form @submit.prevent="handleSubmit(formIsValid)">
								<ValidationProvider rules="required" name="Charity" v-slot="{valid, errors}">
									<b-form-row>
										<b-form-group label="Select a Charity: ">

											<b-form-radio-group v-model="charity" :options="charityOptions"
													:state="errors[0] ? false : (valid ? true : null)">

											</b-form-radio-group>
											<b-form-invalid-feedback id="inputLiveFeedback">{{ errors[0] }}</b-form-invalid-feedback>
										</b-form-group>
									</b-form-row>

								</ValidationProvider>
								<ValidationProvider rules="required" name="Date" v-slot="{valid, errors}">
									<b-form-row>
                                        <b-form-group :label="'Date: ( Next Pickup Date: '  + nextPickupLong +  ') Click Below to Select Date'"
												class="col-form-label">
                                            <b-input-group>
                                                <b-input-group-prepend>
                                                  <b-button variant="outline-info" @click="showCalendar"><b-icon-calendar></b-icon-calendar></b-button>
                                                </b-input-group-prepend>
											    <b-form-input v-model="date" id="date" readonly
													:state="errors[0] ? false : (valid ? true : null)" class="donation-calendar"></b-form-input>
                                            </b-input-group>
											<b-form-invalid-feedback id="inputLiveFeedback">{{ errors[0] }}</b-form-invalid-feedback>
										</b-form-group>
										<!--										<span @click.prevent="setSpecialPickup">{{ customFormatter(specPickupDate) }}</span>-->
									</b-form-row>
								</ValidationProvider>
								<ValidationProvider rules="required" name="Size" v-slot="{valid, errors}">
									<b-form-row>
										<b-form-group label="Size of Donation: ">

											<b-form-radio-group v-model="size" :options="sizeOptions"
													:state="errors[0] ? false : (valid ? true : null)">

											</b-form-radio-group>
											<b-form-invalid-feedback id="inputLiveFeedback">{{ errors[0] }}</b-form-invalid-feedback>
										</b-form-group>
									</b-form-row>

								</ValidationProvider>
								<ValidationProvider rules="required" name="Location" v-slot="{valid, errors}">
									<b-form-row>
										<b-form-group label="Location of Donation: ">

											<b-form-radio-group v-model="location" :options="locationOptions"
													:state="errors[0] ? false : (valid ? true : null)">

											</b-form-radio-group>
											<b-form-invalid-feedback id="inputLiveFeedback">{{ errors[0] }}</b-form-invalid-feedback>
										</b-form-group>
									</b-form-row>

								</ValidationProvider>
								<b-form-row>
									<b-form-group label="Special Instructions: ">
										<b-form-textarea v-model="specialInstructions" id="specialInstructions" rows="8">

										</b-form-textarea>
									</b-form-group>
								</b-form-row>
								<button type="submit" class="btn btn-primary">Confirm Pickup</button>

							</b-form>

						</ValidationObserver>
					</div>
				</div>
			</div>
		</main>

	</div>
</template>

<script>
	import axios from 'axios'
	import Cookies from 'js-cookie'
	import moment from 'moment'
	import storeMixin from '@/mixins/store-mixin';
	import { ValidationObserver, ValidationProvider } from 'vee-validate'
	import { showBeforeDateArray } from '@/date-utils';
	import { bannerStatus } from '@/mixins/banner-status-mixin';

	let date = moment().date(11);

	let maxDate = date.toDate();

	export default {
		name: 'Donations',
		mixins: [ storeMixin, bannerStatus ],
		components: {
			ValidationProvider,
			ValidationObserver
		},
		mounted: function () {
			let numMonths;
			let parser = new UAParser();

			let result = parser.getResult();

			let os = result.os.name.toLowerCase();
			let devices = 'android||avantgo||blackberry||blazer||compal||elaine||fennec||ios||hiptop||iemobile||ip(hone|od)||iris||kindle||lge||maemo||midp||mmp||opera m(ob|in)i||palm( os)?||phone||p(ixi|re)\\/||plucker||pocket||psp||symbian||treo||up\\.(browser|link)||vodafone||wap||windows (ce|phone)||xda||xiino)'.split( '||' )

			if ( devices.indexOf( os ) !== -1 ) {
				numMonths = 1
			} else {
				numMonths = 2
			}

			$.datepicker.setDefaults( {
				showOn: 'focus',
				constrainInput: true,
				numberOfMonths: numMonths,
			} );
		},
		data() {
			const now = new Date();
			const tomorrow = new Date( now.getFullYear(), now.getMonth(), now.getDate() + 1 );

			return {
				dayArray: [],
				specPickupDate: '',
				specPickupDay: null,
				bootstrapStyling: true,
				username: this.getUsername,
				goToModify: false,
				charity: '',
				location: '',
				phone: this.getPhone,
				size: '',
				solicitor: '9999',
				specialInstructions: '',
				date: '',
				theDay: null,
				session: null,
				donorID: this.getDonorID,
				pickupID: '',
				pw: this.getpw,
				alertShow: false,
				isSuccess: false,
				isDanger: false,
				showPickupExists: false,
				min: tomorrow,
				alertMessage: '',
				responseVariant: '',
				eNoticeLong: this.longDateFormat( Cookies.get( 'eNoticePickupDate' ) ),
				nextPickupLong: this.longDateFormat( Cookies.get( 'nextPickupDate' ) ),
				eNoticePickupDate: this.getENoticePickupDate,
				specialPickupDate: this.getSpecialPickupDate,
				specialPickupDay: this.getSpecialPickupDay,
				locationOptions: [
					{ text: 'Front Porch', value: 'Front Porch' },
					{ text: 'Driveway', value: 'Driveway' },
					{ text: 'By Garage', value: 'By Garage' },
					{ text: 'Ring Bell', value: 'Ring Bell' }
				],
				charityOptions: [
					{ text: 'Vizavance (formerly Prevent Blindness)', value: 'VIZ' },
					{ text: 'Oklahoma Foundation for the Disabled', value: 'FD' }
				],
				sizeOptions: [
					{ text: 'Small 1-3 Bags/Boxes', value: 'Small 1-3 Bags/Boxes' },
					{ text: 'Medium 4-7 Bags/Boxes', value: 'Medium 4-7 Bags/Boxes' },
					{ text: 'Large 8+ Bags/Boxes or Furniture  ', value: 'Large 8+ Bags/Boxes or Furniture' }
				],

                extraDates:[],
                excludeDates:[]

			}
		},
		created() {
			let that = this;
			let validPickupDays = {
				url: '/dp/api/get-valid-pickup-days',
				method: 'POST',
				data: {
					donorID: that.getDonorID
				}
			};
			axios.request( validPickupDays )
				.then( response => {
				    console.log("donorID:"+ that.getDonorID +" - getValidPickupDays:" + JSON.stringify(response));

					let dayArr = [ 0, 1, 2, 3, 4, 5, 6 ];
					let success = response.data[ 'success' ];
					if ( success ) {
						this.theDay = response.data[ 'day' ];
						_.pull( dayArr, response.data[ 'day' ] );
						_.pull( dayArr, response.data[ 'specialPickupDay' ] );
						that.dayArray = dayArr;
						that.specPickupDate = response.data[ 'specialPickupDate' ];
						that.specPickupDay = response.data[ 'specialPickupDay' ];
                        that.extraDates = response.data[ 'extraDates' ];
                        that.excludeDates = response.data[ 'excludeDates' ];
					} else {
						that.alertShow = true;
						that.responseVariant = 'danger';
						that.alertMessage = response.data[ 'message' ]
					}
				} )
				.catch( error => {
					console.error( 'Error: ' + JSON.stringify( error ) );

				} ).finally( r => {
				this.showDatePicker();
			} )
		},
		methods: {
			formIsValid() {
				this.submit()
				// console.log(this.date)
			},
			showDatePicker() {
				let theDay = this.theDay;
				let eNotice = Cookies.get( 'eNoticePickupDate' );
				console.log("eNotice:" + eNotice);

				let extraDates = this.extraDates;
				let excludeDates = this.excludeDates;

				let that = this;
				$( '#date' ).datepicker( {
					beforeShowDay: function ( date ) {
						return showBeforeDateArray( date, theDay, eNotice, extraDates, excludeDates );
					},
					onSelect: function ( dateText, inst ) {
						let a = $( '#date' );
						a.focus();
						a.blur();
					}
				} )
			},
            showCalendar(){
			    $( '#date' ).focus();
            },
			submit() {
				let that = this;
				let pickupDate = that.getENoticePickupDate;
				let rfcDate = that.date;
				let formatedDate = moment( that.date ).format( 'MM/DD/YYYY' )
				let solicitorNumber;
				if ( that.date === pickupDate ) {
					solicitorNumber = '9998'
				} else {
					solicitorNumber = '9999'
				}
				let donationOptions = {
					url: '/dp/api/schedule-pickup',
					method: 'POST',
					data: {
						username: that.getUsername,
						charity: that.charity,
						location: that.location,
						phone: that.getPhone,
						size: that.size,
						solicitor: solicitorNumber,
						specialInstructions: that.specialInstructions,
						date: that.date,
						session: null,
						donorID: that.getDonorID,
						pw: that.getpw
					}
				};
				// console.log(donationOptions)
				axios.request( donationOptions )
					.then( response => {
						let success = response.data[ 'success' ];
						let pickupExists = response.data[ 'pickupExists' ];
						if ( success && !pickupExists ) {
							that.alertShow = true;
							that.responseVariant = 'success';
							that.alertMessage = response.data[ 'message' ];
							let stateObj = {};
							stateObj.pickupExists = true;
							stateObj.pickupID = response.data[ 'pickupID' ];
							that.setstate( stateObj );
							that.goToModify = true;
						} else if ( success && pickupExists ) {
							that.showPickupExistsModal( response )
						} else {
							that.alertShow = true;
							that.responseVariant = 'danger';
							that.alertMessage = response.data[ 'message' ];
							that.goToModify = false;
						}
					} )
					.catch( error => {
						console.error( 'Error: ' + JSON.stringify( error ) );

					} )
			},
			modifyAndCancel() {
				this.goToModify = true;
				this.clearAlert()
			},
			customFormatter( date ) {
				return moment( date ).format( 'YYYY-MM-DD' )
			},
			longDateFormat( date ) {
				return moment( date ).format( 'dddd, MMMM Do YYYY' )
			},
			cancelPickup() {
				let now = new Date();
				let today = this.customFormatter( now );
				let time = moment().hour();
				if ( today === this.pickupDate && time >= 8 ) {
					this.alertShow = true;
					this.responseVariant = 'danger';
					this.alertMessage = 'You cannot modify or cancel after 8:00AM on the day of a scheduled pickup';
				} else {
					this.goToModify = false;
					this.showPickupExists = false;
					this.clearAlert();
					this.cancelDonation();
				}
			},
			showPickupExistsModal( response ) {
				this.showPickupExists = true;
				this.alertShow = true;
				this.responseVariant = '';
				this.alertMessage = response.data[ 'message' ];
				let modal = $( '#modal-center' );
				modal.addClass( 'pickup-exists' )
			},
			cancelDonation() {
				let that = this;
				let deletionOptions = {
					url: '/dp/api/cancel-pickup',
					method: 'POST',
					data: {
						username: that.getUsername,
						pw: that.getpw,
						pickupID: that.getPickupID
					}
				};
				axios.request( deletionOptions )
					.then( response => {
						// console.log( JSON.stringify( response ) )
						let success = response.data[ 'success' ];
						if ( success ) {
							that.alertShow = true;
							that.responseVariant = 'success';
							that.alertMessage = response.data[ 'message' ];
							let stateObj = { pickupExists: false, pickupID: undefined };
							that.setstate( stateObj );
							that.canceled = true;

						} else {
							that.alertShow = true;
							that.responseVariant = 'danger';
							that.alertMessage = response.data[ 'message' ]
						}
					} )
					.catch( error => {
						console.error( 'Error: ' + JSON.stringify( error ) );

					} )
			},
			clearAlert( bvModalEvt ) {
				this.alertMessage = '';
				this.responseVariant = '';
				this.alertShow = false;
				if ( this.goToModify ) {
					this.$router.push( { name: 'modify-cancel-donation' } );
				}
			},
			setSpecialPickup() {
				this.date = this.specPickupDate;
			}
		}
	}
</script>

<style scoped>
	form label {
		display: block;
	}

	.donation-form {
		width: 100%;
	}

    .donation-bottom{
        z-index: -1;
    }

	form.donation-form .form-pickup {
		text-align: left;
	}

	.vdp-datepicker__calendar header .prev:not(.disabled):hover,
	.vdp-datepicker__calendar header .next:not(.disabled):hover,
	.vdp-datepicker__calendar header .up:not(.disabled):hover,
	.day__month_btn.up:hover {
		background: transparent !important;
	}


	.vdp-datepicker.donation .vdp-datepicker__calendar > div > header {
		height: auto;
	}

	.donation-calendar header {
		padding-top: 0;
		height: initial;
		position: initial;
		z-index: initial;
		top: 0;
		right: 0;
		left: 0;
	}

	.pickup-exists footer.modal-footer {
		display: none;
	}

	input#date[readonly="readonly"] {
		background-color: white;
	}

	.row.show-pickup-modal .btn-primary {
		height: 62px;
	}

		.mobile.modal-open .row.show-pickup-modal .btn-primary {
		height: 42px;
		width: 100%;
	}

	.mobile.modal-open .row.show-pickup-modal .btn-primary:first-child {
		margin-bottom: 10px;
	}

	#ui-datepicker-div {
		z-index: 1000 !important;
	}
</style>
