<template>
	<div>
		<section class="slideshow featured">
			<article>
				<h1 class="text-white strong">Donation Drop Box</h1>
			</article>
			<img src="../assets/featured-image-sec_donations.jpg" width="1920" height="361" alt="Donation Drop Box">
		</section>
		<div class="banner">
			<b-modal id="modal-center" centered v-model="alertShow"
					:body-bg-variant="responseVariant"
					:header-bg-variant="responseVariant"
					:footer-bg-variant="responseVariant"
					:no-close-on-backdrop="true"
					:no-close-on-esc="true"
					@ok="clearAlert"
			>
				<p class="my-4">{{alertMessage}}</p>
			</b-modal>
			<h4>
				{{ this.status }}
			</h4>
		</div>
		<main>
			<article class="contents text-left drop-box-result">
				<p class="condensed">Donation Drop Box now has donation boxes in the OKC metro area. These are great for smaller
					donations that do not include any larger items. Find the one nearest you.</p>
				<h4 class="mb-10 drop-box-location">Box Locations</h4>
				<ul>
					<li v-for="location in dropBoxLocations" class="box-row">
						<div class="location-name">{{ location['locationName'] }},</div>
						<div>
							<a :href="`${location['mapLink']}`" class="location-address" target="_blank"><span
									class="margin-left">{{ location['locationAddress'] }}</span></a>
						</div>
					</li>
				</ul>
			</article>
		</main>
	</div>

</template>

<script>
	import axios from 'axios'
	import { BModal, VBModal, BButton } from 'bootstrap-vue'
	import { bannerStatus } from '@/mixins/banner-status-mixin';

	export default {
		name: 'donation-drop-box',
		data() {
			return {
				dataReceived: false,
				dropBoxLocations: [],
				alertShow: false,
				isSuccess: false,
				isDanger: false,
				alertMessage: '',
				responseVariant: ''
			}
		},
		components: {
			'b-modal': BModal,
			'b-button': BButton

		},
		directives: {
			'b-modal': VBModal
		},
		mixins: [ bannerStatus ],
		created() {
			let that = this;
			let dropBoxOptions = {
				url: '/dp/api/find-drop-boxes',
				method: 'GET',
				data: {}
			};
			axios.request( dropBoxOptions )
				.then( response => {
					that.dataReceived = true;

					that.dropBoxLocations = response.data[ 'data' ];
					// that.setMapLink(that.dropBoxLocations)
				} )
				.catch( error => {
					console.error( 'Error: ' + JSON.stringify( error ) );
					that.alertShow = true;
					that.responseVariant = 'danger';
					that.alertMessage = 'Something went wrong, please contact us.' //TODO: Add better error message
				} )
		},
		methods: {
			setMapLink( dropBoxLocations ) {
				let that = this;
				let tmpObj = {};
				for ( const box of dropBoxLocations ) {
					let tmpArr = [];
					let ad = box[ 1 ];
					let stripped = ad.replace( '.', '' );
					let escaped = stripped.split( ' ' ).join( '+' );
					box.push( escaped );
					tmpObj.push( box )
				}
				that.dropBoxLocations = tmpObj;

			},
			submit() {
				let that = this;
				let dropBoxOptions = {
					url: '/dp/api/find-drop-boxes',
					method: 'GET',
					data: {}
				};
				console.log( dropBoxOptions );
				axios.request( dropBoxOptions )
					.then( response => {
						// console.log( JSON.stringify( response ) );
						that.dataReceived = true;
						let success = response.data[ 'success' ];
						if ( success ) {
							that.alertShow = true;
							that.responseVariant = 'success';
							that.alertMessage = response.data[ 'message' ]
						} else {
							that.alertShow = true;
							that.responseVariant = 'danger';
							that.alertMessage = response.data[ 'message' ]
						}
					} )
					.catch( error => {
						console.log( 'Error: ' + JSON.stringify( error ) );

					} )
			},
			clearAlert( bvModalEvt ) {
				this.alertMessage = '';
				this.responseVariant = '';
				this.alertShow = false;
			}
		},

	}
</script>

<style scoped>

	p.condensed {
		font-weight: 500;
		font-size: 16px;
		width: 90%;
	}

	.drop-box-result
	li::before {

	}

	.box-row {
		display: flex;
		align-items: baseline;
	}

	.box-row::before {
		margin-bottom: 5px;
	}


	span.margin-left {
		margin-left: 10px;
	}


	.contents ul li::before {
		content: "";
		background: url(./images/bullet.png) no-repeat scroll top left;
		background-size: 50% 50%;
		width: 18px;
		height: 18px;
		display: block;
		position: absolute;
		top: 14px;
		left: 3px;
	}

	p.condensed {
		font-size: 18px;
	}

	/*h4 {*/
	/*	margin: 0 0 24px;*/
	/*	font: 700 70px/1.4 "Muli", sans-serif;*/
	/*	color: #000000;*/
	/*	letter-spacing: 1px;*/
	/*}*/

	/*h4.drop-box-location {*/
	/*	font-size: 24px;*/
	/*}*/

	.contents ul li.box-row {
		list-style: none;
		position: relative;
		margin-bottom: 5px;
		padding-left: 20px;
		line-height: 36px;
	}

	h1.strong {
		font-weight: 700;
	}

	.location-name {
		font-weight: 700;
	}

	.box-row > div {
		display: block;
	}

	@media screen and (max-width: 700px) {
		.box-row, .box-row > div {
			display: block;
		}

		span.margin-left {
			margin-left: 0;
		}

	}
</style>
