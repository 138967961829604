<template>
	<div>
		<section class="slideshow featured">
			<article>
				<h1 class="text-white strong">Confirm Email</h1>
			</article>
			<img src="../assets/featured-image-sec_login.jpg"
					width="1920"
					height="361" alt="Login">
		</section>
		<div class="banner">
			<b-modal id="modal-center" centered v-model="alertShow"
					:body-bg-variant="responseVariant"
					:header-bg-variant="responseVariant"
					:footer-bg-variant="responseVariant"
					:no-close-on-backdrop="true"
					:no-close-on-esc="true"
					@ok="clearAlert"
			>
				<p class="my-4">{{alertMessage}}</p>
			</b-modal>
		</div>
		<main>
			<article class="contents">
				<div v-if="confirmationBusy">
					<div class="text-center">
						<b-spinner label="Spinning"></b-spinner>
						<b-spinner type="grow" label="Spinning"></b-spinner>
						<b-spinner variant="primary" label="Spinning"></b-spinner>
						<b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
						<b-spinner variant="success" label="Spinning"></b-spinner>
						<b-spinner variant="success" type="grow" label="Spinning"></b-spinner>
						<h1>Confirming Email</h1>
					</div>
				</div>
				<div v-else-if="!confirmationBusy">
					<div class="text-center">
					<h4>Thank you for confirming your email.<br>
					Please <router-link to="/login">Login</router-link> to schedule your first pickup.</h4>
					</div>

				</div>
			</article>
		</main>
	</div>

</template>
<script>
	import axios from 'axios'
	import { BSpinner, BModal, BButton, VBModal } from 'bootstrap-vue'


	export default {
		name: 'Confirm',
		data() {
			return {
				alertShow: false,
				isSuccess: false,
				isDanger: false,
				confirmationBusy: true,
				alertMessage: '',
				responseVariant: ''
			}
		},
		created() {
			let that = this;
			let confirmationOptions = {
				url: '/dp/api/confirm-email',
				method: 'POST',
				data: {
					uuid: that.$route.query[ 'uuid' ]
				}
			};
			axios.request( confirmationOptions )
				.then( response => {
						console.log( JSON.stringify( response ) );
						let success = response.data[ 'success' ]
						if ( success ) {
							// console.log('Success!!!')
							that.confirmationBusy = false;
							/*that.alertShow = true;
							that.responseVariant = 'success';
							that.alertMessage = response.data[ 'message' ];*/
						} else {
							console.log('Failure!!!')
						}
					}
				)
				.catch( error => {
						console.error( 'Error: ' + JSON.stringify( error ) );
						that.confirmationBusy = !that.confirmationBusy;
						that.responseVariant = 'danger';
						that.alertMessage = 'Something went wrong, please contact us.'; //TODO: better error message
					}
				)
		},
		methods: {
			clearAlert( bvModalEvt ) {
				this.alertMessage = '';
				this.responseVariant = '';
				this.alertShow = false;
			}
		},
		components: {
			BSpinner,
			'b-modal': BModal,
			'b-button': BButton

		},
		directives: {
			'b-modal': VBModal
		}
	}
</script>

<style scoped>

</style>
