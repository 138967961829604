import Vue from 'vue'
import Cookies from 'js-cookie'
import VueRouter from 'vue-router'
import storeMixin from '@/mixins/store-mixin';

import Home from '@/views/Home.vue'
import Login from '@/views/Login';
import ModifyCancelDonation from '@/views/ModifyCancelDonation';
import ReviewPastDonations from '@/views/ReviewPastDonations';
import Register from '@/views/Register';
import DonorInformation from '@/views/DonorInformation';
import Donations from '@/views/Donations';
import Contact from '@/views/Contact';
import DonationDropBox from '@/views/DonationDropBox';
import AboutUs from '@/views/AboutUs';
import FAQ from '@/views/FAQ';
import AcceptableItems from '@/views/AcceptableItems';
import Privacy from '@/views/Privacy';
import ChangePassword from '@/views/ChangePassword';
import VizavanceReceipt from '@/views/VizavanceReceipt';
import FDReceipt from '@/views/FDReceipt';
import Confirm from '@/views/Confirm';

import store from '@/store/index';

Vue.use( VueRouter );

const routes = [
	{ path: '/', name: 'default', component: Login },
	{ path: '/login', name: 'login', component: Login },
	{ path: '/modify-cancel-donation', name: 'modify-cancel-donation', component: ModifyCancelDonation },
	{ path: '/review-past-donations', name: 'review-past-donations', component: ReviewPastDonations },
	{ path: '/register', name: 'register', component: Register },
	{ path: '/donations', name: 'donations', component: Donations },
	{ path: '/donor-information', name: 'donor-information', component: DonorInformation },
	{ path: '/contact', name: 'contact', component: Contact },
	// { path: '/donation-drop-box', name: 'donation-drop-box', component: DonationDropBox },
	{ path: '/home', name: 'home', component: Home },
	{ path: '/about-us', name: 'about-us', component: AboutUs },
	{ path: '/faq', name: 'faq', component: FAQ },
	{ path: '/acceptable-items', name: 'acceptable-items', component: AcceptableItems },
	{ path: '/privacy', name: 'privacy', component: Privacy },
	{ path: '/change-password', name: 'change-password', component: ChangePassword },
	{ path: '/vizavance-receipt', name: 'vizavance-receipt', component: VizavanceReceipt, props: true },
	{ path: '/fd-receipt', name: 'fd-receipt', component: FDReceipt, props: true },
	{ path: '/confirm', name: 'confirm', component: Confirm }
];

const router = new VueRouter( {
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior( to, from, savedPosition ) {
		return { x: 0, y: 0 }
	}
} );

router.beforeEach( ( to, from, next ) => {
	let isLoggedIn = store.getters.isLoggedIn;
	let pickupExists = store.getters.pickupExists;

	let openDivs = document.getElementsByClassName( 'open' );
	let openNavDivs = document.getElementsByClassName( 'nav-open' );
	for ( const div of openNavDivs ) {
		div.classList.remove( 'nav-open' );
	}
	for ( const div of openDivs ) {
		div.classList.remove( 'open' );
	}
	let openDivsCheck = document.getElementsByClassName( 'open' );
	if ( openDivsCheck.length > 0 ) {
		for ( const div of openDivsCheck ) {
			div.classList.remove( 'open' );
		}
	}

	//redirect
	if( to.name === 'home' ){
		window.location.href = "https://donationpickup.org";
	}else if( to.name === 'contact' ){
		window.location.href = "https://donationpickup.org/contact";
	}else if( to.name === 'about-us' ){
		window.location.href = "https://donationpickup.org";
	}else if( to.name === 'faq' ){
		window.location.href = "https://donationpickup.org/faqs/";
	}else if( to.name === 'acceptable-items' ){
		window.location.href = "https://donationpickup.org/acceptable-items/";
	}else if( to.name === 'privacy' ){
		window.location.href = "https://donationpickup.org/privacy/";
	}

	if ( to.name === 'donations' || to.name === 'modify-cancel-donation' || to.name === 'review-past-donations' || to.name === 'donor-information' ) {
		// console.log('TO:' + to.name)
		next();
		if ( !isLoggedIn && ( isLoggedIn !== undefined || true ) ) {
			next( '/login' )
		} else {
			next()
		}
	} else {
		next()
	}
} );


export default router
