<template>
	<div>
		<table width="100%" bgcolor="#fff" border="0" cellpadding="0" cellspacing="0">
			<tr>
				<td>
					<table bgcolor="#ffffff" class="content" align="center" cellpadding="0" cellspacing="0" border="0">
						<tr>
							<td bgcolor="#ffffff" class="header">
								<table width="100%" align="left" border="0" cellpadding="20" cellspacing="0">
									<tr>
										<td height="40" bgcolor="#ffffff">
											<a href="http://www.donationpickup.org"><img
													src="../assets/DonationPickup.org-email.png" width="320"
													height="158" alt="DonationPickup.Org" class="img"></a>
										</td>
									</tr>
								</table>
							</td>
						</tr>
						<tr>
							<td bgcolor="#ffffff" class="header">
								<table width="100%" align="left" border="0" cellpadding="0" cellspacing="0">
									<tr>
										<td bgcolor="#ffffff">
											<img src="../assets/vizavance-header.jpg" alt="Vizavance Header" class="img">
										</td>
									</tr>
								</table>
							</td>
						</tr>
						<tr>
							<td>
								<table width="100%" border="0" cellspacing="0" cellpadding="20">
									<tr>
										<td class="bodycopy innerpadding">

											<h3>RECEIPT OF DONATION</h3>
											<p>Vizavance<br>
												50 Penn Place, 1900 N.W. Expressway, <br>
												Suite R110<br>
												Oklahoma City, OK 73118</p>
											<div class="row">
												<div class="col-md-3 strong">
													Fed ID:
												</div>
												<div class="col">
													73-6108998
												</div>
											</div>
											<div class="row">
												<div class="col-md-3 strong">
													Date Received:
												</div>
												<div class="col">
													{{ this.$attrs.props['date'] }}
												</div>
											</div>
											<div class="row">
												<div class="col-md-3 strong">
													Received From:
												</div>
												<div class="col">
													{{ this.$attrs.props.donor['name'] }} <br>
													{{ this.$attrs.props.donor['streetAddress'] }} <br>
													{{ this.$attrs.props.donor['cityStateZip'] }} <br>
												</div>
											</div>
											<div class="row">
												<div class="col-md-3 strong">
													Estimated Value:
												</div>
												<div class="col">

												</div>
											</div>
											<div class="row">
												<div class="col-md-3 strong">
													Items Donated:
												</div>
												<div class="col">
													________________________________________ <br>
													________________________________________ <br>
													________________________________________ <br>
												</div>
											</div>
											<hr class="margin-top-bottom">
											<p>All items donated are deductible for income tax purposes at their present fair market value.
												Internal Revenue Code places the responsibility for estimating this value upon the donor
												rather than the agency receiving the gift. As with any tax-related information, individual
												donors
												should always consult a tax professional to resolve questions regarding their particular
												donation.Please consult Form 8283 and Publication 526 and 561 on the Internal Revenue Service
												website ​<a href="http://www.irs.gov​">www.irs.gov​</a>.</p>
										</td>
									</tr>
								</table>
							</td>
						</tr>
						<tr>
							<td class="innerpadding bodycopy">
								<p><a href="http://www.donationpickup.org/donations.html"><img
										src="../assets/b_schedule-free-pickup.png" width="300"
										height="65" alt="Schedule Free Pickup"></a></p>
								<p><a href="http://www.donationpickup.org"><img src="../assets/b_visit-our-website.png"
										height="65"
										alt="Visit Our Website"></a></p>
							</td>
						</tr>
						<tr>
							<td class="footer" bgcolor="#fff">
								<table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#fff">
									<tr>
										<td class="footercopy" bgcolor="#fff">
											<p><a href="mailto:donorservices@donationpickup.org" style="color: #00a6dd">donorservices@donationpickup.org</a>
											</p>
											<p>&copy; 2020 DonationPickup.org. </p>
											<p>Designed by myheartcreative and 360Works.</p>
										</td>
									</tr>
									<tr>
										<td class="footercopy" bgcolor="#fff">
											<p><a href="https://www.facebook.com/Donationpickuporg-186739551336112/"><img
													src="../assets/icon-facebook.png" width="30" height="30" alt="Facebook"></a></p>
										</td>
									</tr>
									<tr>
										<td class="footercopy" bgcolor="#fff">
											<a href="https://vizavance.org/"><img src="../assets/vizavance.png" height="80"
													alt="Vizavance"></a>
										</td>
									</tr>
								</table>
							</td>
						</tr>
					</table>
				</td>
			</tr>
		</table>
	</div>
</template>

<script>
	export default {
		name: 'VizavanceReceipt',
		mounted() {
			let header = document.getElementsByTagName( 'header' );
			let footer = document.getElementsByTagName( 'footer' );
			let headerList = header.item( 0 ).classList;
			let footerList = footer.item( 0 ).classList;
			headerList.add( 'display-none' );
			footerList.add( 'display-none' );
		},
		destroyed() {
			let header = document.getElementsByTagName( 'header' );
			let footer = document.getElementsByTagName( 'footer' );
			let headerList = header.item( 0 ).classList;
			let footerList = footer.item( 0 ).classList;
			headerList.remove( 'display-none' );
			footerList.remove( 'display-none' );
		},
		methods: {}
	}
</script>

<style scoped>

	.strong {
		font-weight: 700;
	}

	body {
		margin: 0;
		padding: 0;
		min-width: 100% !important;
		background-color: #fff;
		font-family: 'muli', arial, sans-serif;
	}

	.img {
		height: auto;
		max-width: 100%;
	}


	.content {
		width: 95%;
		max-width: 700px;
	}

	.header {
		padding: 40px 0 10px;
		background-color: #fff;
	}

	.innerpadding {
		padding: 40px 0;
	}

	.h2, .h3, .bodycopy {
		color: #222;
		font-family: 'muli', arial, sans-serif;
	}

	.unorderedlist {
		font-size: 19px;
		line-height: 28px;
		color: #0071b9;
	}

	.unorderedlist li {
		margin-bottom: 8px;
	}

	hr {
		border-collapse: collapse;
		border-width: 1px 0 0;
		border-color: #00a6dd;
	}

	.bodycopy {
		font-size: 19px;
		line-height: 28px;
	}

	.footer {
		padding: 20px 0 70px;
		border-top: 1px solid #00a6dd;
		background-color: #fff;
	}

	.footercopy {
		font-family: 'muli', arial, sans-serif;
		font-size: 14px;
		color: #222;
	}


	@media only screen and (max-width: 550px), screen and (max-device-width: 550px) {
		body[yahoo] .hide {
			display: none !important;
		}
	}

	.margin-top-bottom {
		margin-top: 25px;
		margin-bottom: 25px;
	}
</style>
