import moment, { now } from 'moment';

function isValidPickupDate( pickupDate, validDay, eNotice, extra, exclude ) {
	let todayDate = new Date();
	let now = moment();

	todayDate.setHours( 0, 0, 0, 0 );
	let thirtyDaysOutDate = new Date();
	thirtyDaysOutDate.setHours( 0, 0, 0, 0 );
	thirtyDaysOutDate.setDate( thirtyDaysOutDate.getDate() + 30 );
	let isValid = true;
	let eNoticeDate = new Date( Date.parse( eNotice ) );
	eNoticeDate.setHours( 0, 0, 0, 0 );
	let cutoff = moment(pickupDate);

	// Check extra, exclude
	let tempDate = cutoff.format("MM/DD/YYYY");
    if(extra !== undefined){
    	if(extra.includes(tempDate))
    		return true;
	}
	if(exclude !== undefined){
    	if(exclude.includes(tempDate))
    		return false;
	}

	if ( now.date() === cutoff.date() ) {
		if (now.hour() > 8 ) {
			return false
		}
	}
	if ( pickupDate.getTime() === eNoticeDate.getTime() ) {
		return true;
	}

	function getletiableHoliday( year, month, possibleDates, refDay ) {
		let date = new Date();
		date.setFullYear( year, month, refDay );
		date.setHours( 0, 0, 0, 0 );
		let day = date.getDay();
		let returnDate = new Date();
		returnDate.setFullYear( year, month, possibleDates[ day ] );
		returnDate.setHours( 0, 0, 0, 0 );
		return returnDate;
	}

	function thanksgivingDate( year ) {
		let month = 10;
		let refDay = 1;
		let thanksgivingPossibleDates = [ 26, 25, 24, 23, 22, 28, 27 ];
		return getletiableHoliday(
			year,
			month,
			thanksgivingPossibleDates,
			refDay )
	}

	function laborDayDate( year ) {
		let month = 8;
		let refDay = 1;
		let laborDayPossibleDates = [ 2, 1, 7, 6, 5, 4, 3 ];
		return getletiableHoliday( year, month, laborDayPossibleDates, refDay );
	}

	function christmasEveDate( year ) {
		let date = new Date();
		date.setFullYear( year, 11, 24 );
		date.setHours( 0, 0, 0, 0 );
		return date;
	}

	function christmasDate( year ) {
		let date = new Date();
		date.setFullYear( year, 11, 25 );
		date.setHours( 0, 0, 0, 0 );
		return date;
	}

	function newYearsDate( year ) {
		let date = new Date();
		date.setFullYear( year, 0, 1 );
		date.setHours( 0, 0, 0, 0 );
		return date;
	}

	function newYearsEveDate( year ) {
		let date = new Date();
		date.setFullYear( year, 11, 31 );
		date.setHours( 0, 0, 0, 0 );
		return date;
	}

	function independenceDayDate( year ) {
		let date = new Date();
		date.setFullYear( year, 6, 4 );
		date.setHours( 0, 0, 0, 0 );
		return date;
	}

	function memorialDayDate( year ) {
		let month = 4;
		let refDay = 31;
		let memorialDayPossibleDates = [ 25, 31, 30, 29, 28, 27, 26 ];
		return getletiableHoliday(
			year,
			month,
			memorialDayPossibleDates,
			refDay
		);
	}

	function blackFridayDate( year ) {
		let date = thanksgivingDate( year );
		date.setDate( date.getDate() + 1 );
		date.setHours( 0, 0, 0, 0 );
		return date;
	}

	function checkToday() {

	}

	if (
		pickupDate.getDay() !== validDay ||
		pickupDate.getTime() === todayDate.getTime() ||
		pickupDate.getTime() < todayDate.getTime() ||
		thirtyDaysOutDate.getTime() < pickupDate.getTime() ||
		pickupDate.getDay() === 6 ||
		pickupDate.getDay() === 0 ||
		pickupDate.getTime() === thanksgivingDate( pickupDate.getFullYear() ).getTime() ||
		pickupDate.getTime() === memorialDayDate( pickupDate.getFullYear() ).getTime() ||
		pickupDate.getTime() === laborDayDate( pickupDate.getFullYear() ).getTime() ||
		pickupDate.getTime() === christmasDate( pickupDate.getFullYear() ).getTime() ||
		pickupDate.getTime() === christmasEveDate( pickupDate.getFullYear() ).getTime() ||
		pickupDate.getTime() === newYearsDate( pickupDate.getFullYear() ).getTime() ||
		pickupDate.getTime() === newYearsEveDate( pickupDate.getFullYear() ).getTime() ||
		pickupDate.getTime() === independenceDayDate( pickupDate.getFullYear() ).getTime() ||
		pickupDate.getTime() === blackFridayDate( pickupDate.getFullYear() ).getTime()
	) {
		isValid = false;
	}
	return isValid;
}

function showBeforeDateArray( date, validDay, eNotice , extra, exclude) {
	let isValidDate = isValidPickupDate( date, validDay, eNotice, extra, exclude );
	//console.log("validDay:" +  JSON.stringify(validDay) + " - date:" + JSON.stringify(date) + " - " + isValidDate);

	//
	// if ( (date.getDate() > 11 && date.getMonth() === 3) || (date.getMonth() >= 4 ) ) {
	// 	isValidDate = false;
	// }

	let cssClass = '';
	let popupMsg = '';
	if ( !isValidDate ) {
		popupMsg = 'You cannot schedule a pickup on this date';
	}
	return [ isValidDate, cssClass, popupMsg ];
}

export { showBeforeDateArray }
