var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('table',{attrs:{"width":"100%","bgcolor":"#fff","border":"0","cellpadding":"0","cellspacing":"0"}},[_c('tr',[_c('td',[_c('table',{staticClass:"content",attrs:{"bgcolor":"#ffffff","align":"center","cellpadding":"0","cellspacing":"0","border":"0"}},[_vm._m(0),_vm._m(1),_c('tr',[_c('td',[_c('table',{attrs:{"width":"100%","border":"0","cellspacing":"0","cellpadding":"20"}},[_c('tr',[_c('td',{staticClass:"bodycopy innerpadding"},[_c('h3',[_vm._v("RECEIPT OF DONATION")]),_vm._m(2),_vm._m(3),_c('p'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3 strong"},[_vm._v(" Date Received: ")]),_c('div',{staticClass:"col"},[_vm._v(" "+_vm._s(this.$attrs.props['date'])+" ")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3 strong"},[_vm._v(" Received From: ")]),_c('div',{staticClass:"col"},[_vm._v(" "+_vm._s(this.$attrs.props.donor['name'])+" "),_c('br'),_vm._v(" "+_vm._s(this.$attrs.props.donor['streetAddress'])+" "),_c('br'),_vm._v(" "+_vm._s(this.$attrs.props.donor['cityStateZip'])+" "),_c('br')])]),_vm._m(4),_vm._m(5),_c('hr'),_vm._m(6)])])])])]),_vm._m(7),_vm._m(8)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"header",attrs:{"bgcolor":"#ffffff"}},[_c('table',{attrs:{"width":"100%","align":"left","border":"0","cellpadding":"20","cellspacing":"0"}},[_c('tr',[_c('td',{attrs:{"height":"40","bgcolor":"#ffffff"}},[_c('a',{attrs:{"href":"http://www.donationpickup.org"}},[_c('img',{staticClass:"img",attrs:{"src":require("../assets/DonationPickup.org-email.png"),"width":"320","height":"158","alt":"DonationPickup.Org"}})])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"header",attrs:{"bgcolor":"#ffffff"}},[_c('table',{attrs:{"width":"100%","align":"left","border":"0","cellpadding":"0","cellspacing":"0"}},[_c('tr',[_c('td',{attrs:{"bgcolor":"#ffffff"}},[_c('img',{staticClass:"img",attrs:{"src":require("../assets/okfd-header.jpg"),"alt":"OKFD Header"}})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("OKFD"),_c('br'),_vm._v(" 8421 N Walker"),_c('br'),_vm._v(" Oklahoma City, OK 73114")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3 strong"},[_vm._v(" Fed ID: ")]),_c('div',{staticClass:"col"},[_vm._v(" 73-0708241 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3 strong"},[_vm._v(" Estimated Value: ")]),_c('div',{staticClass:"col"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3 strong"},[_vm._v(" Items Donated: ")]),_c('div',{staticClass:"col"},[_vm._v(" ________________________________________ "),_c('br'),_vm._v(" ________________________________________ "),_c('br'),_vm._v(" ________________________________________ "),_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("All items donated are deductible for income tax purposes at their present fair market value. Internal Revenue Code places the responsibility for estimating this value upon the donor rather than the agency receiving the gift. As with any tax-related information, individual donors should always consult a tax professional to resolve questions regarding their particular donation.Please consult Form 8283 and Publication 526 and 561 on the Internal Revenue Service website ​"),_c('a',{attrs:{"href":"http://www.irs.gov​"}},[_vm._v("www.irs.gov​")]),_vm._v(".")])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"innerpadding bodycopy"},[_c('p',[_c('a',{attrs:{"href":"http://www.donationpickup.org/donations.html"}},[_c('img',{attrs:{"src":require("../assets/b_schedule-free-pickup.png"),"height":"65","alt":"Schedule Free Pickup"}})])]),_c('p',[_c('a',{attrs:{"href":"http://www.donationpickup.org"}},[_c('img',{attrs:{"src":require("../assets/b_visit-our-website.png"),"height":"65","alt":"Visit Our Website"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"footer",attrs:{"bgcolor":"#fff"}},[_c('table',{attrs:{"width":"100%","border":"0","cellspacing":"0","cellpadding":"0","bgcolor":"#fff"}},[_c('tr',[_c('td',{staticClass:"footercopy",attrs:{"bgcolor":"#fff"}},[_c('p',[_c('a',{staticStyle:{"color":"#00a6dd"},attrs:{"href":"mailto:donorservices@donationpickup.org"}},[_vm._v("donorservices@donationpickup.org")])]),_c('p',[_vm._v("© 2020 DonationPickup.org. ")]),_c('p',[_vm._v("Designed by myheartcreative and 360Works.")])])]),_c('tr',[_c('td',{staticClass:"footercopy",attrs:{"bgcolor":"#fff"}},[_c('p',[_c('a',{attrs:{"href":"https://www.facebook.com/Donationpickuporg-186739551336112/"}},[_c('img',{attrs:{"src":require("../assets/icon-facebook.png"),"width":"30","height":"30","alt":"Facebook"}})])])])]),_c('tr',[_c('td',{staticClass:"footercopy",attrs:{"bgcolor":"#fff"}},[_c('a',{attrs:{"href":"http://www.okfd.org/"}},[_c('img',{attrs:{"src":require("../assets/okfd.png"),"height":"80","alt":"Okfd"}})])])])])])])
}]

export { render, staticRenderFns }