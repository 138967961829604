<template>
	<div>
		<section class="slideshow featured">
			<article>
				<h1 class="text-white strong">About Us</h1>
			</article>
			<img src="../assets/featured-image-sec_about.jpg" width="1920" height="361" alt="About Us">
		</section>
		<div class="banner">
			<h4>
				{{ this.status }}
			</h4>
		</div>
		<main>
			<article class="contents">
				<div class="container">
					<p>We have partnered with Vizavance and the Oklahoma Foundation for the Disabled since 1988. Our team raises funds
						for these local, Oklahoma nonprofits by requesting, collecting, and selling clothing and household items in
						good condition. Our goal is to give you a reliable and straightforward way to
						transform unwanted household items into funds for these remarkable charities. Your generous donations
						provide steady, monthly revenue, which allows organizations to focus more on serving our community and less
						on fundraising.</p>
					<div class="row">
						<div class="col-md">
							<p><img src="../assets/donations.jpg" width="597" height="397" alt="Donations"></p>
							<h3>Being Environmentally Friendly </h3>
							<p>Your donated items will find new homes with our appreciative thrift store customers. The stores provide
								our
								friends and neighbors with usable pieces at low prices. When things do not sell, they typically go to
								companies that ship them to other countries or use them as industrial rags, keeping them out of
								landfills.</p>
						</div>
						<div class="col-md">

							<p><img src="../assets/jobs.jpg" width="597" height="397" alt="Jobs</p>">
							<h3>Providing Jobs in the Community</h3>
							<p>Our managers, truck drivers, store personnel, and telemarketers, live here in Oklahoma. Your generosity
								provides Oklahomans with full and part-time jobs that have above average pay in the industry.</p>
						</div>
					</div>
				</div>
			</article>
		</main>
	</div>
</template>

<script>
	import { bannerStatus } from '@/mixins/banner-status-mixin';
	export default {
		name: 'about-us',
		mixins: [bannerStatus]
	}
</script>

<style scoped>

</style>
