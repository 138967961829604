<template>
	<div>
		<section class="slideshow featured">
			<article>
				<h1 class="text-white strong">Review Past Donations</h1>
			</article>
			<img src="../assets/featured-image-sec_donations.jpg" width="1920" height="361" alt="Review Past Donations">
		</section>
		<div class="banner">
			<b-modal id="modal-center" centered v-model="alertShow"
					:body-bg-variant="responseVariant"
					:header-bg-variant="responseVariant"
					:footer-bg-variant="responseVariant"
					:no-close-on-backdrop="true"
					:no-close-on-esc="true"
					@ok="clearAlert"
			>
				<p class="my-4">{{alertMessage}}</p>
			</b-modal>
			<h4>
				{{ this.status }}
			</h4>
		</div>
		<main>
			<article class="">
				<div class="container text-center" v-if="hasNotDonated">
					<h4>You have not yet donated. Please
						<router-link to="/donations" class="btn-link">Schedule a Pickup here.</router-link>
					</h4>
				</div>
				<div class="container-md" v-else>
					<div class="row">
						<div class="col-md donation-pagination text-left">
							Showing {{( (currentPage * perPage) - perPage )+ 1 }} to {{ currentPage * perPage }} of {{ totalRows }}
						</div>
						<div class="col-md text-center">
							<div class="row">
								<div class="col-md">
									<BFormGroup label="Per Page">

									</BFormGroup>
								</div>
								<div class="col-md">
									<BFormSelect
											v-model="perPage"
											id="perPageSelect"
											size="sm"
											:options="perPageOptions">

									</BFormSelect>
								</div>
							</div>
						</div>
						<div class="col-md donation-pagination text-right">
							<BPagination
									v-model="currentPage"
									:total-rows="totalRows"
									:per-page="perPage"
									align="fill"
									size="sm"
									class="my-0"
							>
							</BPagination>
						</div>
					</div>
					<BTable
							:items="items"
							:fields="fields"
							:current-page="currentPage"
							:per-page="perPage"
					>
						<template v-slot:cell(charity)="row">
							<div class="text-center">
								{{ row.item.charity }}
							</div>
						</template>
						<template v-slot:cell(receipt)="row">
							<div class="text-center btn-link" @click.prevent="getReceipt(row.item)">
								{{ row.item.receipt }}
							</div>
						</template>
					</BTable>
				</div>
			</article>
		</main>
	</div>
</template>

<script>


	import axios from 'axios'
	import { BModal, VBModal, BButton, BTable, BPagination, BFormGroup, BFormSelect } from 'bootstrap-vue'
	import storeMixin from '@/mixins/store-mixin';
	import { bannerStatus } from '@/mixins/banner-status-mixin';

	export default {
		name: 'review-past-donations',
		mixins: [ storeMixin, bannerStatus ],
		components: {
			BTable,
			BPagination,
			BFormGroup,
			BFormSelect,
			'b-modal': BModal,
			'b-button': BButton

		},
		directives: {
			'b-modal': VBModal
		},
		data() {
			return {
				alertShow: false,
				isSuccess: false,
				isDanger: false,
				hasNotDonated: false,
				alertMessage: '',
				donations: [],
				items: [],
				currentPage: 1,
				totalRows: 1,
				perPage: 10,
				perPageOptions: [ 5, 10, 15 ],
				showingRange: this.currentPage * this.perPage,
				responseVariant: '',
				fields: [
					{
						label: 'Date',
						key: 'date'
					},
					{
						label: 'Charity',
						key: 'charity'
					},
					{
						label: 'Size of Donation',
						key: 'size'
					},
					{
						label: 'Status',
						key: 'status'
					},
					{
						label: 'Instructions',
						key: 'specialInstructions'
					},
					{
						label: 'Receipt',
						key: 'receipt'
					},

				]
			}
		},
		created: function () {
			let that = this;
			let reviewOptions = {
				url: '/dp/api/review-past-donations',
				method: 'POST',
				data: {
					donorID: that.getDonorID
				}
			};
			axios.request( reviewOptions )
				.then( response => {
					// console.log( JSON.stringify( response ) );
					let success = response.data[ 'success' ];
					if ( success ) {
						that.hasNotDonated = true;
					} else {
						that.donations = response.data[ 'data' ];
						that.setItems( that.donations );
					}
				} )
				.catch( error => {
					console.error( 'Error: ' + JSON.stringify( error ) );
					that.alertShow = true;
					that.alertMessage = 'Something went wrong, please contact us.'; //TODO: Better error message
					that.responseVariant = 'danger';
				} )
		},
		methods: {
			setItems( donations ) {
				let tmpObj = {};
				for ( const donate of donations ) {
					let donorObj = {
						name: donate[ 'name' ],
						streetAddress: donate[ 'streetAddress' ],
						cityStateZip: donate[ 'cityStateZip' ]
					};


					tmpObj = {
						'date': donate[ 'date' ],
						'charity': donate[ 'charity' ],
						'size': donate[ 'size' ],
						'status': donate[ 'status' ],
						'specialInstructions': donate[ 'specialInstructions' ],
						'receipt': donate[ 'receipt' ],
						donor: donorObj
					};
					this.items.push( tmpObj )
				}
				this.totalRows = this.items.length;
			},
			resetModal( bvModalEvt ) {
				this.alertMessage = '';
				this.responseVariant = '';
				this.alertShow = false;
			},
			clearAlert() {
				this.alertMessage = '';
				this.alertShow = false;
				this.isSuccess = false;
				this.isDanger = false;
			},
			getReceipt( donor ) {
				if ( donor.charity === 'PBO' || donor.charity === 'VIZ' ) {
					this.$router.push( {
						name: 'vizavance-receipt',
						params: { props: donor }
					} )
				} else if ( donor.charity === 'FD' ) {
					this.$router.push( {
						name: 'fd-receipt',
						params: { props: donor }
					} )
				} else {
					this.alertShow = true;
					this.isDanger = true;
					this.alertMessage = 'There is no available receipt for this charity, please contact us.' //TODO: better error message
				}
			}
		},


	}
</script>

<style scoped>
	.donation-pagination {
		margin-bottom: 10px;
	}
</style>
