<template>
	<div>
		<section class="slideshow featured">
			<article>
				<h1 class="text-white strong">Register</h1>
			</article>
			<img src="../assets/featured-image-sec_register.jpg" width="1920" height="361" alt="Register">
		</section>
		<div class="banner">
			<b-modal id="modal-center" centered v-model="alertShow"
					:body-bg-variant="responseVariant"
					:header-bg-variant="responseVariant"
					:footer-bg-variant="responseVariant"
					:no-close-on-backdrop="true"
					:no-close-on-esc="true"
					@ok="clearAlert"
			>
				<div v-if="duplicateExists">
					<h5 class="my-4">{{alertMessage}}</h5><br>
					<div class="row duplicate-modal">
						<div class="col-md">
							<b-button type="button" class="btn btn-primary" @click.prevent="removeAndRegister">Update Address
							</b-button>
						</div>
						<div class="col-md">
							<b-button type="button" class="btn btn-primary" @click.prevent="differentEmail">Use Different Email
							</b-button>
						</div>
					</div>
				</div>
				<div v-else>
					<p class="my-4">{{alertMessage}}</p>
				</div>
			</b-modal>
			<h4>
				{{ this.status }}
			</h4>
		</div>
		<main>
			<article class="contents">
				<div class="container-md">
					<div class="row">
						<div class="col-md">
							<b-form>
								<div class="form-pickup">
									<b-form-group class="form-input register-address" label="Enter Your Street Address">
										<b-input-group class="form-input">
											<b-form-input type="text" placeholder="Enter Your Street Address" v-model="address"
													:class="{'readonly' : addressIsValid}"
													id="address"></b-form-input>
										</b-input-group>
									</b-form-group>
								</div>
								<div class="autocomplete-results" v-if="isAutocomplete && !suggestionIsSelected">
<!--                                    v-if="!suggestionIsSelected" -->
									<h4>Please click on your address from the list below</h4>
									<ul>
										<li v-for="suggestedAddress in this.autocompleteResults"
												@click.prevent="setSelected(suggestedAddress)">
											{{ suggestedAddress.text }}
										</li>
									</ul>
								</div>
								<!-- <button type="submit" class="btn btn-primary">Verify Address</button> -->
<!--								<div class="text-left">-->
<!--									<button type="button" class="btn btn-primary mb-10" @click.prevent="submit(selectedAddress)"-->
<!--											v-if="suggestionIsSelected">Verify Address-->
<!--									</button>-->
<!--								</div>-->
							</b-form>
							<ValidationObserver v-slot="{handleSubmit}">
								<b-form class="donation-form" @submit.prevent="handleSubmit(completeRegistration)">
									<div v-if="suggestionIsSelected && addressIsValid">
										<div class="row">
											<div class="col-md">
												<div class="form-pickup">
													<ValidationProvider rules="required" v-slot="{valid,errors}" name="First Name">
														<b-form-group label="First Name">
															<b-form-input v-model="registrationData.firstName"
																	:state="errors[0] ? false : (valid ? true : null)">
															</b-form-input>
															<b-form-invalid-feedback id="inputLiveFeedback">{{ errors[0] }}</b-form-invalid-feedback>
														</b-form-group>
													</ValidationProvider>
												</div>
											</div>
											<div class="col-md">
												<div class="form-pickup">
													<ValidationProvider rules="required" v-slot="{valid, errors}" name="Last Name">
														<b-form-group label="Last Name">
															<b-form-input v-model="registrationData.lastName"
																	:state="errors[0] ? false : (valid ? true : null)">
															</b-form-input>
															<b-form-invalid-feedback id="inputLiveFeedback">{{ errors[0] }}</b-form-invalid-feedback>
														</b-form-group>
													</ValidationProvider>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-md">
												<div class="form-pickup">
													<ValidationProvider rules="required|email" v-slot="{valid, errors}" name="Email">
														<b-form-group label="Email" description="We'll never share your email with anyone else.">
															<b-form-input type="email" v-model="registrationData.email"
																	:state="errors[0] ? false : (valid ? true : null)">
															</b-form-input>
															<b-form-invalid-feedback id="inputLiveFeedback">{{ errors[0] }}</b-form-invalid-feedback>
														</b-form-group>
													</ValidationProvider>
												</div>
											</div>
											<div class="col-md">
												<div class="form-pickup">
													<ValidationProvider rules="required" v-slot="{valid, errors}" name="Phone">
														<b-form-group label="Mobile Telephone" description="No Dashes">
															<b-form-input type="text" v-model="registrationData.phone"
																	:state="errors[0] ? false : (valid ? true : null)">
															</b-form-input>
															<b-form-invalid-feedback id="inputLiveFeedback">{{ errors[0] }}</b-form-invalid-feedback>
														</b-form-group>
													</ValidationProvider>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-md">
												<div class="form-pickup">
													<b-form-group label="Sign up for eNotices?">
														<b-form-radio-group
																id="enotice"
																v-model="registrationData.eNotices"
																:options="radioOptions"
																name="eNotices">
														</b-form-radio-group>
													</b-form-group>
												</div>
											</div>
											<div class="col-md">
												<div class="form-pickup">
													<div class="row">
														<div class="col-md">
															<b-form-group label="Receive Text Messages?">
																<b-form-radio-group
																		id="sms"
																		v-model="registrationData.sendSMS"
																		:options="radioOptions"
																		name="radio-options">
																</b-form-radio-group>
															</b-form-group>
														</div>
														<div class="col-md">
															<div v-if="registrationData.sendSMS" class="registration-select">
																<ValidationProvider rules="required" v-slot="{valid, errors}" name="Mobile Carrier">
																	<b-form-group label="Mobile Carrier">
																		<BFormSelect v-model="registrationData.carrier" :options="carrierOptions"
																				id="carrier"
																				:state="errors[0] ? false : (valid ? true : null)">
																		</BFormSelect>
																		<b-form-invalid-feedback id="inputLiveFeedback">{{ errors[0] }}
																		</b-form-invalid-feedback>
																	</b-form-group>
																</ValidationProvider>
															</div>
															<div v-else>

															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-md">

												<div class="form-pickup">
													<b-form-group class="form-group registration-select" label="Security Question">

														<BFormSelect v-model="registrationData.securityQuestion" :options="securityQuestionOptions"
																id="securityQuestion"></BFormSelect>
													</b-form-group>
												</div>
											</div>
											<div class="col-md">

												<div class="form-pickup">
													<b-form-group class="form-group" label="Security Question Answer">
														<input type="text" class="form-control" id="securityAnswer"
																v-model="registrationData.securityAnswer">
													</b-form-group>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-md">
												<div class="form-pickup">
													<ValidationProvider rules="required" v-slot="{valid, errors}" name="Password" vid="password">
														<b-form-group label="Password">
															<b-form-input type="password" v-model="password" id="password"
																	:state="errors[0] ? false : (valid ? true : null)">
															</b-form-input>
															<b-form-invalid-feedback id="inputLiveFeedback">{{ errors[0] }}</b-form-invalid-feedback>
														</b-form-group>
													</ValidationProvider>
												</div>
											</div>
											<div class="col-md">

												<div class="form-pickup">
													<ValidationProvider rules="required|confirmed:password" v-slot="{valid, errors}"
															name="Confirm Password">
														<b-form-group label="Confirm Password">
															<b-form-input type="password" v-model="passwordCheck"
																	:state="errors[0] ? false : (valid ? true : null)">
															</b-form-input>
															<b-form-invalid-feedback id="inputLiveFeedback">{{ errors[0] }}</b-form-invalid-feedback>
														</b-form-group>
													</ValidationProvider>
												</div>
											</div>
										</div>
										<div class="text-left">
											<button type="button" class="btn btn-primary" v-if="!addressIsValid" @click.prevent="submit">
												Submit
											</button>
										</div>
										<div class="text-left">
											<button type="submit" class="btn btn-primary" v-if="addressIsValid"
											>Complete Registration
											</button>
										</div>
									</div>
								</b-form>
							</ValidationObserver>
						</div>
					</div>
				</div>
			</article>
		</main>
	</div>
</template>

<script>
	import axios from 'axios'
	import XRegExp from 'xregexp'
	import { ValidationObserver, ValidationProvider } from 'vee-validate'


	import {
		BFormSelect,
		BIcon,
		BInputGroup,
		BInputGroupPrepend,
		BInputGroupAppend,
		BFormInput,
		BIconSearch,
		BIconXCircle,
		BModal,
		BButton,
		VBModal
	} from 'bootstrap-vue'
	import { bannerStatus } from '@/mixins/banner-status-mixin';

	export default {
		name: 'Register',
		components: {
			BFormSelect,
			BIcon,
			BInputGroupPrepend,
			BInputGroupAppend,
			BInputGroup,
			BFormInput,
			BIconSearch,
			BIconXCircle,
			'b-modal': BModal,
			'b-button': BButton,
			ValidationProvider,
			ValidationObserver
		},
		mixins: [ bannerStatus ],
		directives: {
			'b-modal': VBModal
		},
		data() {
			return {
                alertShow: false,
				duplicateExists: false,
				isSuccess: false,
				isDanger: false,
				plain: true,
				alertMessage: '',
				streetPostDirection: '',
				goToLogin: false,
				recordID: '',
				validZips: [],
				addressIsValid: false,
				addressFreeform: '',
				addressFreeformZip: '',
				selectedAddress: {},
				timeout: null,
				addressIsTouched: false,
				debounceMilliseconds: 50,
				isAutocomplete: false,
				autocompleteResults: [],
				answer: '',
				responseVariant: '',
				password: '',
				passwordCheck: '',
				suggestionIsSelected: false,
                addressChangedFromSuggestionSelection: false,
				registrationData: {
					HouseNumber: '',
					Dir: '',
					StreetName: '',
					PostOffice: '',
					State: '',
					ZipCode: '',
					PostDirection: '',
					GateCode: '',
					Location: '',
					firstName: '',
					lastName: '',
					email: '',
					phone: '',
					securityQuestion: null,
					securityAnswer: '',
					sendSMS: 0,
					eNotices: 1,
					pw: '',
					carrier: null,
					deliveryLine: ''
				},
				email: '',
				phone: '',
				firstName: '',
				lastName: '',
				sendSMS: false,
				securityQuestion: '',
				securityAnswer: '',
				address: '',
				addressZip: '',
				streetSuffix: '',
				selected: null,
				smartAddress: {},
				suggestionLength: 4,
				radioOptions: [
					{ value: 1, text: 'Yes' },
					{ value: 0, text: 'No' }
				],
				carrierOptions: [
					{ value: null, text: 'Please select mobile carrier' },
					{ value: 'ATT', text: 'AT&T' },
					{ value: 'Alltel', text: 'Alltel' },
					{ value: 'Boost Mobile', text: 'Boost Mobile' },
					{ value: 'Cricket', text: 'Cricket' },
					{ value: 'GIV Mobile', text: 'GIV Mobile' },
					{ value: 'Google Fi', text: 'Google Fi' },
					{ value: 'Sprint', text: 'Sprint' },
					{ value: 'MetroPCS', text: 'MetroPCS' },
					{ value: 'Republic', text: 'Republic' },
					{ value: 'Straight Talk', text: 'Straight Talk' },
					{ value: 'T-Mobile', text: 'T-Mobile' },
					{ value: 'Ting', text: 'Ting' },
					{ value: 'US Cellular', text: 'US Cellular' },
					{ value: 'Verizon', text: 'Verizon' },
					{ value: 'Virgin Mobile', text: 'Virgin Mobile' },
					{ value: 'Xfinity', text: 'Xfinity' }

				],
				securityQuestionOptions: [
					{ value: null, text: 'Please select a security question' },
					{ value: 'What was your childhood nickname?', text: 'What was your childhood nickname?' },
					{
						value: 'What is your maternal grandmother\'s maiden name?',
						text: 'What is your maternal grandmother\'s maiden name?'
					},
					{
						value: 'What city did you meet your spouse/significant other?',
						text: 'What city did you meet your spouse/significant other?'
					},
					{ value: 'What is your oldest sibling\'s middle name?', text: 'What is your oldest sibling\'s middle name?' }
				]
			}
		},
		mounted: function () {

			let parser = new UAParser();

			let result = parser.getResult();

			let os = result.os.name.toLowerCase();
			let devices = 'android||avantgo||blackberry||blazer||compal||elaine||fennec||hiptop||iemobile||ip(hone|od)||iris||kindle||lge||maemo||midp||mmp||opera m(ob|in)i||palm( os)?||phone||p(ixi|re)\\/||plucker||pocket||psp||symbian||treo||up\\.(browser|link)||vodafone||wap||windows (ce|phone)||xda||xiino)'.split( '||' )

			if ( devices.indexOf( os ) !== -1 ) {
				this.suggestionLength = 1
			}
		},
		watch: {
			address: function ( newVal, oldVal ) {
				this.debouncedGetAnswer();
			}
		},
		created: function () {
			this.debouncedGetAnswer = _.debounce( this.getAnswer, 500 )
		},
		methods: {
			getAnswer() {
			    //console.log( 'address changed');
			    if(!this.addressChangedFromSuggestionSelection) {
                    this.suggestionIsSelected = false;
                    if( this.address.length > this.suggestionLength ) {
                        this.autocomplete()
                    } else {
                        this.isAutocomplete = false;
                        this.autocompleteResults = [];
                    }
                }
			    this.addressChangedFromSuggestionSelection = false;

			},
			submit( selectedAddress ) {
				this.smartAddress = selectedAddress;
				this.getValidZips();
			},
			clearAddress() {
				this.suggestionIsSelected = false;
				this.addressIsTouched = false;
				this.addressIsValid = false;
				this.isAutocomplete = false;
				this.autocompleteResults = [];
				this.address = '';
				this.password = '';
				this.passwordCheck = '';
				this.duplicateExists = false;
				this.goToLogin = false;
				this.streetSuffix = '';
				this.streetPostDirection = '';
				this.registrationData = {
					HouseNumber: '',
					Dir: '',
					StreetName: '',
					PostOffice: '',
					State: '',
					ZipCode: '',
					PostDirection: '',
					GateCode: '',
					Location: '',
					firstName: '',
					lastName: '',
					email: '',
					phone: '',
					securityQuestion: null,
					securityAnswer: '',
					sendSMS: 0,
					eNotices: 1,
					pw: '',
					carrier: null,
					deliverLine: ''
				};
				this.selectedAddress = {}
			},
			getSuggestions() {
				if ( this.address.length < 7 ) {
					console.log( 'The address is too short...' + this.address.length )
				} else if ( this.suggestionIsSelected === false ) {
					this.autocomplete()
				}
			},
			autocompleteCheck: function () {
				if ( this.address.length < 7 ) {
					// console.log( this.address.length )
				} else {
					// console.log( this.address.length )
				}
			},
			getValidZips() {
				let that = this;
				let validZipOptions = {
					method: 'GET',
					url: '/dp/api/get-valid-zips',
					data: {}
				};
				axios.request( validZipOptions )
					.then( function ( response ) {
						that.validZips = response.data[ 'data' ];
						that.compareZipToValid();
					} ).catch( function ( error ) {
					console.error( JSON.stringify( error ) )
				} )
			},
			compareZipToValid() {
				let that = this;
				let verified = false;
				// that.selectedAddress = that.address;
				for ( const zip of that.validZips ) {
					if ( zip.zip === that.addressZip ) {
						verified = !verified;
						that.submitToSmarty();
					}
				}
				// if ( that.validZips.indexOf( that.selectedAddress[ 'zipcode' ] ) !== -1 ) { //that.fmdata.ZipCode
				// 	that.submitToSmarty();
				// } else if ( that.validZips.indexOf( that.addressZip ) !== -1 ) {
				// 	that.submitToSmarty();
				// } else {
				// 	that.responseVariant = 'danger';
				// 	that.alertShow = true;
				// 	that.alertMessage = 'We\'re sorry, the Zip Code you provided is not within our current pickup area.'
				// }

				if ( !verified ) {
					that.responseVariant = 'danger';
					that.alertShow = true;
					that.alertMessage = 'We\'re sorry, the Zip Code you provided is not within our current pickup area.';
				}

			},
			submitToSmarty: function () {
				let that = this;
				let smartyAddress = '';
				if ( !that.suggestionIsSelected ) {
					smartyAddress = that.address
				} else {
					smartyAddress = that.smartAddress[ 'street_line' ] + ' ' + that.smartAddress.city + ' ' + that.smartAddress.state + ' ' + that.smartAddress[ 'zipcode' ]
				}
				const stripAddress = XRegExp( '[,.\'\\"`;<>@*^$]', 'g' );
				const repl = '';
				let escaped = XRegExp.replace( smartyAddress, stripAddress, repl );
				let streetyOptions = {
					url: 'https://us-street.api.smartystreets.com/street-address?key=14842323673431934&street=' + escaped.split( ' ' ).join( '+' ),
					method: 'GET',
					data: {},
					responseType: 'json'
				};
				axios.request( streetyOptions )
					.then( function ( response ) {
						let smartyData = response.data[ 0 ];
						if ( smartyData !== undefined ) {
							that.validateDonation( smartyData );
							// console.log( JSON.stringify( `SmartyData: ${ smartyData }` ) );
						} else {
							that.alertShow = true;
							that.responseVariant = 'danger';
							that.alertMessage = 'We\'re sorry but the address you entered cannot be verified.  Please Contact Us.'
						}
					} ).catch( function ( error ) {
					that.alertShow = true;
					that.responseVariant = 'danger';
					let msg;
					if ( error.response ) {
						msg = error.response.data;
						that.alertMessage = msg;
					} else if ( error.request ) {
						msg = error.request;
						that.alertMessage = msg;
					} else {
						msg = error.message;
						that.alertMessage = msg;
					}
				} )
			},
			autocomplete() {
			    //console.log("Autocomplete called");
				let that = this;
				let st = that.address;

				clearTimeout( that.timeout );
				// let smartyUrl = 'https://us-autocomplete.api.smartystreets.com/suggest?key=14842323673431934&prefix=' + st.split( ' ' ).join( '+' ) + '&state_filter=ok';
				let smartyUrl = 'https://us-autocomplete-pro.api.smartystreets.com/lookup?key=14842323673431934&search=' + st.split( ' ' ).join( '+' ) + '&include_only_states=ok';
				that.timeout = setTimeout( () => {
					const smartPromise = axios.get( smartyUrl );
					Promise.all( [ smartPromise ] ).then( value => {
						let smartyData = value[ 0 ].data;
						// console.log( JSON.stringify( smartyData ) );
						if ( value[ 0 ].data[ 'suggestions' ] !== null ) {
							that.buildAddress( smartyData[ 'suggestions' ] );
							let suggestionOptions = {};
							let suggestionsCount = smartyData[ 'suggestions' ].length;
							that.autocompleteResults = smartyData[ 'suggestions' ];
							that.isAutocomplete = true;
						}
					} ).catch( error => {
						console.log( error )
					} )
				}, that.debounceMilliseconds );
			},
			buildAddress( suggestions ) {
				let tmpArr = [];
				for ( const suggestion of suggestions ) {
					let whiteSpace = '';
					if ( suggestion.secondary ) {
						if ( suggestion.entries > 1 ) {
							suggestion.secondary += ' (' + suggestion.entries + ' entries)';
						}
						whiteSpace = ' ';
					}
					suggestion.text = suggestion[ 'street_line' ] + whiteSpace + suggestion.secondary + ' ' + suggestion.city + ', ' + suggestion.state + ', ' + suggestion[ 'zipcode' ];
					suggestion.value = suggestion[ 'street_line' ] + whiteSpace + suggestion.secondary + ' ' + suggestion.city + ', ' + suggestion.state + ', ' + suggestion[ 'zipcode' ];
					tmpArr.push( suggestion )
				}
				return tmpArr
			},
			setSelected( text ) {
				this.address = text.text;
				this.addressChangedFromSuggestionSelection = true;
				this.selectedAddress = text;
				this.registrationData.ZipCode = text[ 'zipcode' ];
				this.addressZip = text[ 'zipcode' ];
				this.suggestionIsSelected = true;
				this.isAutocomplete = false;
				this.addressIsValid = false;
				this.submit(text);
			},
			validateDonation( smartyData ) {
				let that = this;
				let components = smartyData.components;
				let record_type = smartyData.metadata[ 'record_type' ];
				let rdi = smartyData.metadata[ 'rdi' ];
				let dpv_match_code = smartyData[ 'analysis' ][ 'dpv_match_code' ];
				let footnotesRes = smartyData[ 'analysis' ][ 'footnotes' ];

				if ( ( record_type === 'F' || record_type === 'S' ) && rdi !== 'Commercial' && dpv_match_code === 'Y' ) {
					that.registrationData.HouseNumber = components[ 'primary_number' ];
					that.registrationData.Dir = components[ 'street_predirection' ];
					that.registrationData.StreetName = components[ 'street_name' ];
					/*if ( components['street_suffix' !== undefined || ''] ) {
						that.registrationData.StreetName = components[ 'street_name' ] + ' ' + components[ 'street_suffix' ];
					 } else {

					}*/
					that.streetSuffix = components[ 'street_suffix' ];
					that.streetPostDirection = components[ 'street_postdirection' ];
					that.registrationData.PostOffice = components[ 'default_city_name' ];
					that.registrationData.State = components[ 'state_abbreviation' ];
					that.registrationData.ZipCode = components[ 'zipcode' ];
					that.registrationData.deliveryLine = smartyData[ 'delivery_line_1' ];
					that.addressIsValid = true;//!that.addressIsValid;
					//that.suggestionIsSelected = true;//!that.suggestionIsSelected;
					// that.registrationData.jsonResponse = JSON.stringify( smartyData );
				} else {
					that.alertShow = true;
					that.responseVariant = 'danger';

					that.addressIsValid = false;
					that.suggestionIsSelected = false;

					if ( rdi === 'Commercial' ) {
						that.alertMessage = 'Contact us regarding scheduling a pickup at a business.'
					} else if ( record_type === 'H' ) {
						that.alertMessage = 'Unfortunately we are unable to pickup in an apartment complex or multi unit housing.'
					} else {
						that.alertMessage = 'We are unable to verify your address, please Contact us.'
					}
				}
			},
			completeRegistration() {
				let that = this;
				let sliceNum = 1;

				if ( that.registrationData.Dir !== undefined ) {
					sliceNum = 2;
				}


				let escaped = that.registrationData.phone.replace( /\D+/g, '' );

				let strArr = that.registrationData.deliveryLine.split( ' ' );
				let completeStreet = strArr.slice( sliceNum ).join( ' ' );

				let addressLookupOptions = {
					method: 'POST',
					url: '/dp/api/address-lookup',
					data: {
						'HouseNumber': that.registrationData.HouseNumber,
						'Dir': that.registrationData.Dir,
						'StreetName': completeStreet,
						'PostOffice': that.registrationData.PostOffice,
						'State': that.registrationData.State,
						'ZipCode': that.registrationData.ZipCode,
						'PostDirection': that.streetPostDirection,
						'GateCode': that.registrationData.GateCode,
						'Location': that.registrationData.Location,
						'firstName': that.registrationData.firstName,
						'lastName': that.registrationData.lastName,
						'email': that.registrationData.email,
						'phone': escaped,
						'securityQuestion': that.registrationData.securityQuestion,
						'securityAnswer': that.registrationData.securityAnswer,
						'sms': that.registrationData.sendSMS ? 1 : 0,
						'eNotices': that.registrationData.eNotices ? 1 : 0,
						'pw': that.password,
						'carrier': that.registrationData.carrier,
						'deliveryLine': that.registrationData.deliveryLine
					}
				};
				// console.log( addressLookupOptions );
				axios.request( addressLookupOptions )
					.then( response => {
						// console.log( JSON.stringify( `Address Lookup Response: ${ response }` ) )
						let success = response.data[ 'success' ];
						let duplicateExists = response.data[ 'duplicateExists' ];
						if ( success ) {
							that.alertShow = true;
							that.responseVariant = 'success';
							that.alertMessage = response.data[ 'message' ];
							that.goToLogin = true;
						} else if ( duplicateExists ) {
							let msg = response.data[ 'message' ];
							that.duplicateExists = true;
							that.alertShow = true;
							that.alertMessage = msg;
							that.responseVariant = '';
						} else {
							that.alertShow = true;
							that.responseVariant = 'danger';
							that.alertMessage = response.data[ 'message' ]
						}
					} )
					.catch( error => {
						console.error( 'Error: ' + JSON.stringify( error ) );

					} )
			},
			removeAndRegister() {
				let that = this;
				let sliceNum = 1;
				let escaped = that.registrationData.phone.replace( /\D+/g, '' );
				if ( that.registrationData.Dir !== undefined ) {
					sliceNum = 2;
				}
				let strArr = that.registrationData.deliveryLine.split( ' ' );
				let completeStreet = strArr.slice( sliceNum ).join( ' ' );
				that.clearAlert();
				that.duplicateExists = false;
				let removeOptions = {
					url: '/dp/api/remove-and-register',
					method: 'POST',
					data: {
						'HouseNumber': that.registrationData.HouseNumber,
						'Dir': that.registrationData.Dir,
						'StreetName': completeStreet,
						'PostOffice': that.registrationData.PostOffice,
						'State': that.registrationData.State,
						'ZipCode': that.registrationData.ZipCode,
						'PostDirection': that.streetPostDirection,
						'GateCode': that.registrationData.GateCode,
						'Location': that.registrationData.Location,
						'firstName': that.registrationData.firstName,
						'lastName': that.registrationData.lastName,
						'email': that.registrationData.email,
						'phone': escaped,
						'securityQuestion': that.registrationData.securityQuestion,
						'securityAnswer': that.registrationData.securityAnswer,
						'sms': that.registrationData.sendSMS ? 1 : 0,
						'eNotices': that.registrationData.eNotices ? 1 : 0,
						'pw': that.password,
						'carrier': that.registrationData.carrier,
						'deliveryLine': that.registrationData.deliveryLine
					}
				};
				// alert('Working on bug...please use different email.')
				axios.request( removeOptions )
					.then( response => {
						console.log( JSON.stringify( response ) );
						let success = response.data[ 'success' ];
						if ( success ) {
							that.alertShow = true;
							that.responseVariant = 'success';
							that.alertMessage = response.data[ 'message' ];
							that.goToLogin = true;
						} else {
							that.alertShow = true;
							that.responseVariant = 'danger';
							that.alertMessage = response.data[ 'message' ]
						}
					} )
					.catch( error => {
						console.error( 'Error: ' + JSON.stringify( error ) );

					} )
			},
			differentEmail() {
				this.registrationData.email = '';
				this.clearAlert();
				this.duplicateExists = false;
			},
			clearAlert( bvModalEvt ) {
				this.alertMessage = '';
				this.responseVariant = '';
				this.alertShow = false;
				if ( this.goToLogin ) {
					this.$router.push( { name: 'login' } );
				}
			}
		}
	}
</script>

<style scoped>

	.contents ul li::before {
		top: 10px;
	}

	.contents .registration-select select {
		padding-top: 0;
		padding-bottom: 0;
		color: #000;
	}

	.input-group-append {
		height: 34.25px;
		border-left: none;
	}

	.input-group-append:hover {
		cursor: pointer;
	}

	.input-group-text {
		color: white;
		background: red;
	}

	.row.duplicate-modal .btn-primary {
		height: 62px;
	}

	.mobile.modal-open .row.duplicate-modal .btn-primary {
		height: 42px;
		width: 100%;
	}

	.mobile.modal-open .row.duplicate-modal .btn-primary:first-child {
		margin-bottom: 10px;
	}
</style>
