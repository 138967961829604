<template>
	<div>
		<section class="slideshow featured donation-bottom">
			<article>
				<h1 class="text-white strong">Modify/Cancel Pickup</h1>
			</article>
			<img src="../assets/featured-image-home.jpg" width="1920" height="361" alt="Modify/Cancel Pickup"
					class="img-small">
		</section>
		<div class="banner">
			<b-modal id="modal-center" centered v-model="alertShow"
					:body-bg-variant="responseVariant"
					:header-bg-variant="responseVariant"
					:footer-bg-variant="responseVariant"
					:no-close-on-backdrop="true"
					:no-close-on-esc="true"
					@ok="clearAlert"
			>
				<p class="my-4">{{alertMessage}}</p>
			</b-modal>
			<h4>
				{{ this.status }}
			</h4>
		</div>
		<main>
			<div class="container">
				<div class="row">
					<div class="col-md">
						<ValidationObserver v-slot="{handleSubmit}">
							<b-form @submit.prevent="handleSubmit(formIsValid)">
								<ValidationProvider rules="required" name="Charity" v-slot="{valid, errors}">
									<b-form-row>
										<b-form-group label="Select a Charity: ">

											<b-form-radio-group v-model="charity" :options="charityOptions"
													:state="errors[0] ? false : (valid ? true : null)">

											</b-form-radio-group>
											<b-form-invalid-feedback id="inputLiveFeedback">{{ errors[0] }}</b-form-invalid-feedback>
										</b-form-group>
									</b-form-row>

								</ValidationProvider>
								<ValidationProvider rules="required" name="Date" v-slot="{valid, errors}">
									<b-form-row>
                                        <b-form-group :label="'Date: ( Next Pickup Date: '  + nextPickupLong +  ') Click Below to Select Date'"
												class="col-form-label">

                                            <b-input-group>
                                                <b-input-group-prepend>
                                                  <b-button variant="outline-info" @click="showCalendar"><b-icon-calendar></b-icon-calendar></b-button>
                                                </b-input-group-prepend>
                                                <b-form-input v-model="date" id="date" readonly
                                                        :state="errors[0] ? false : (valid ? true : null)" class="donation-calendar"></b-form-input>
                                            </b-input-group>
											<b-form-invalid-feedback id="inputLiveFeedback">{{ errors[0] }}</b-form-invalid-feedback>
										</b-form-group>
										<!--										<span @click.prevent="setSpecialPickup">{{ customFormatter(specPickupDate) }}</span>-->
									</b-form-row>
								</ValidationProvider>
								<ValidationProvider rules="required" name="Size" v-slot="{valid, errors}">
									<b-form-row>
										<b-form-group label="Size of Donation: ">

											<b-form-radio-group v-model="size" :options="sizeOptions"
													:state="errors[0] ? false : (valid ? true : null)">

											</b-form-radio-group>
											<b-form-invalid-feedback id="inputLiveFeedback">{{ errors[0] }}</b-form-invalid-feedback>
										</b-form-group>
									</b-form-row>

								</ValidationProvider>
								<ValidationProvider rules="required" name="Location" v-slot="{valid, errors}">
									<b-form-row>
										<b-form-group label="Location of Donation: ">

											<b-form-radio-group v-model="location" :options="locationOptions"
													:state="errors[0] ? false : (valid ? true : null)">

											</b-form-radio-group>
											<b-form-invalid-feedback id="inputLiveFeedback">{{ errors[0] }}</b-form-invalid-feedback>
										</b-form-group>
									</b-form-row>

								</ValidationProvider>
								<b-form-row>
									<b-form-group label="Special Instructions: ">
										<b-form-textarea v-model="specialInstructions" id="specialInstructions" rows="8">

										</b-form-textarea>
									</b-form-group>
								</b-form-row>
								<button type="submit" class="btn btn-primary">Modify Pickup</button>
								<button type="button" class="btn btn-primary float-right" @click.preven="canCancel">Cancel Pickup
								</button>


							</b-form>

						</ValidationObserver>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
	import axios from 'axios'
	import moment from 'moment'
	import storeMixin from '@/mixins/store-mixin';
	import { ValidationObserver, ValidationProvider } from 'vee-validate';
	import { showBeforeDateArray } from '@/date-utils';
	import Cookies from 'js-cookie';
	import { bannerStatus } from '@/mixins/banner-status-mixin';

	let today = new Date();

	let month = today.getMonth();

	export default {
		name: 'ModifyCancelDonation',
		mixins: [ storeMixin, bannerStatus ],
		components: {
			ValidationProvider,
			ValidationObserver
		},
		data() {
			const now = new Date()
			const tomorrow = new Date( now.getFullYear(), now.getMonth(), now.getDate() + 1 );
			return {
				bootstrapStyling: true,
				date: '',
				dayArray: [],
				username: this.getUsername,
				charity: '',
				specPickupDate: '',
				specPickupDay: null,
				location: '',
				phone: this.getPhone,
				canceled: false,
				size: '',
				solicitor: '',
				specialInstructions: '',
				pickupDate: '',
				session: null,
				donorID: this.getDonorID,
				pickupID: this.getPickupID,
				theDay: null,
				eNoticeLong: this.longDateFormat( Cookies.get( 'eNoticePickupDate' ) ),
                nextPickupLong: this.longDateFormat( Cookies.get( 'nextPickupDate' ) ),
				min: tomorrow,
				pw: this.getpw,
				alertShow: false,
				isSuccess: false,
				isDanger: false,
				alertMessage: '',
				responseVariant: '',
				eNoticePickupDate: this.getENoticePickupDate,
				specialPickupDate: this.getSpecialPickupDate,
				specialPickupDay: this.getSpecialPickupDay,
				locationOptions: [
					{ text: 'Front Porch', value: 'Front Porch' },
					{ text: 'Driveway', value: 'Driveway' },
					{ text: 'By Garage', value: 'By Garage' },
					{ text: 'Ring Bell', value: 'Ring Bell' }
				],
				charityOptions: [
					{ text: 'Vizavance (formerly Prevent Blindness)', value: 'VIZ' },
					{ text: 'Oklahoma Foundation for the Disabled', value: 'FD' }
				],
				sizeOptions: [
					{ text: 'Small 1-3 Bags/Boxes', value: 'Small 1-3 Bags/Boxes' },
					{ text: 'Medium 4-7 Bags/Boxes', value: 'Medium 4-7 Bags/Boxes' },
					{ text: 'Large 8+ Bags/Boxes or Furniture  ', value: 'Large 8+ Bags/Boxes or Furniture' }
				],

                extraDates:[],
                excludeDates:[]
			}
		},
		mounted: function () {
			let numMonths;
			let parser = new UAParser();
      //adding fbq here to track page views for this specific page. Putting it in mounted so it only runs once, and the <template> hates <script> tags.
      fbq('track', 'Schedule');

			let result = parser.getResult();

			let os = result.os.name.toLowerCase();
			let devices = 'android||avantgo||blackberry||blazer||compal||elaine||fennec||ios||hiptop||iemobile||ip(hone|od)||iris||kindle||lge||maemo||midp||mmp||opera m(ob|in)i||palm( os)?||phone||p(ixi|re)\\/||plucker||pocket||psp||symbian||treo||up\\.(browser|link)||vodafone||wap||windows (ce|phone)||xda||xiino)'.split( '||' )
			if ( devices.indexOf( os ) !== -1 ) {
				numMonths = 1
			} else {
				numMonths = 2
			}
			$.datepicker.setDefaults( {
				showOn: 'focus',
				constrainInput: true,
				numberOfMonths: numMonths
			} );
		},
		created() {

			let username = this.getUsername;
			let pw = this.getpw;
			let donorID = this.getDonorID;
			let that = this;
			let getPickupOptions = {
				url: '/dp/api/get-pickup',
				method: 'POST',
				data: {
					username: username,
					pw: pw,
					donorID: donorID
				}
			};
			axios.request( getPickupOptions )
				.then( response => {
					// console.log( JSON.stringify( response.data ) );
					that.charity = response.data[ 'charity' ];
					that.location = response.data[ 'location' ];
					that.size = response.data[ 'size' ];
					that.date = response.data[ 'pickupDate' ];
					this.theDay = response.data[ 'theDay' ];
					that.specialInstructions = response.data[ 'specialInstructions' ];
					that.pickupDate = response.data[ 'rfcDate' ];
					let validPickupDays = {
						url: '/dp/api/get-valid-pickup-days',
						method: 'POST',
						data: {
							donorID: that.getDonorID
						}
					};
					axios.request( validPickupDays )
						.then( response => {
								console.log( JSON.stringify( "validPickupDays:" + JSON.stringify(response) ) );
								let dayArr = [ 0, 1, 2, 3, 4, 5, 6 ];
								let success = response.data[ 'success' ];
								if ( success ) {
									this.theDay = response.data[ 'day' ];
									_.pull( dayArr, response.data[ 'day' ] );
									_.pull( dayArr, response.data[ 'specialPickupDay' ] );
									that.dayArray = dayArr;
									that.specPickupDate = response.data[ 'specialPickupDate' ];
									that.specPickupDay = response.data[ 'specialPickupDay' ];
                                    that.extraDates = response.data[ 'extraDates' ];
                                    that.excludeDates = response.data[ 'excludeDates' ];
								}
							}
						)
						.catch( error => {
							console.error( 'Error: ' + JSON.stringify( error ) );

						} ).finally( r => {
						this.showDatePicker();
					} )
				} )
				.catch( error => {
					console.error( 'Error: ' + JSON.stringify( error ) );
				} )
		},
		methods: {
			formIsValid() {
				let now = new Date();
				let today = this.customFormatter( now );
				let time = moment().hour();

				if ( today === this.pickupDate && time >= 8 ) {
					this.alertShow = true;
					this.responseVariant = 'danger';
					this.alertMessage = '`You cannot modify or cancel a pickup online after 8:00AM on the day of.  Please call (866) 657-4258 to make changes.  Thank you.';
				} else {

					this.submit();
				}
			},
			showDatePicker() {
				let theDay = this.theDay;
				let eNotice = Cookies.get( 'eNoticePickupDate' );

                let extraDates = this.extraDates;
                let excludeDates = this.excludeDates;

				$( '#date' ).datepicker( {
					beforeShowDay: function ( date ) {
						return showBeforeDateArray( date, theDay, eNotice, extraDates, excludeDates );
					},
					onSelect: function ( dateText, inst ) {
						let a = $( '#date' );
						a.focus();
						a.blur();
					}
				} )
			},

            showCalendar(){
			    $( '#date' ).focus();
            },

			customFormatter( date ) {
				return moment( date ).format( 'YYYY-MM-DD' )
			},
			longDateFormat( date ) {
				return moment( date ).format( 'dddd, MMMM Do YYYY' )
			},
			canCancel() {


				let now = new Date();
				let today = this.customFormatter( now );
				let time = moment().hour();

				if ( today === this.pickupDate && time >= 8 ) {
					this.alertShow = true;
					this.responseVariant = 'danger';
					this.alertMessage = 'You cannot modify or cancel a pickup online after 8:00AM on the day of.  Please call (866) 657-4258 to make changes.  Thank you.';
				} else {

					this.cancelDonation();
				}
			},
			submit() {
				let that = this;
				let pickupDate = that.getENoticePickupDate;
				let solicitorNumber;
				if ( that.date === pickupDate ) {
					solicitorNumber = '9998'
				} else {
					solicitorNumber = '9999'
				}
				let donationOptions = {
					url: '/dp/api/modify-pickup',
					method: 'POST',
					data: {
						username: that.getUsername,
						charity: that.charity,
						location: that.location,
						phone: that.getPhone,
						size: that.size,
						solicitor: solicitorNumber,
						specialInstructions: that.specialInstructions,
						date: that.date,
						session: null,
						donorID: that.getDonorID,
						pw: that.getpw,
						pickupID: that.getPickupID
					}
				};
				axios.request( donationOptions )
					.then( response => {
						// console.log( JSON.stringify( response ) )
						let success = response.data[ 'success' ];
						if ( success ) {
							that.alertShow = true;
							that.responseVariant = 'success';
							that.alertMessage = response.data[ 'message' ]
						} else {
							that.alertShow = true;
							that.responseVariant = 'danger';
							that.alertMessage = response.data[ 'message' ]
						}
					} )
					.catch( error => {
						console.error( 'Error: ' + JSON.stringify( error ) );

					} )
			},
			cancelDonation() {
				let that = this;
				let deletionOptions = {
					url: '/dp/api/cancel-pickup',
					method: 'POST',
					data: {
						username: that.getUsername,
						pw: that.getpw,
						pickupID: that.getPickupID
					}
				};
				axios.request( deletionOptions )
					.then( response => {
						// console.log( JSON.stringify( response ) )
						let success = response.data[ 'success' ];
						if ( success ) {
							that.alertShow = true;
							that.responseVariant = 'success';
							that.alertMessage = response.data[ 'message' ];
							let stateObj = { pickupExists: false, pickupID: undefined };
							that.setstate( stateObj );
							that.canceled = true;

						} else {
							that.alertShow = true;
							that.responseVariant = 'danger';
							that.alertMessage = response.data[ 'message' ]
						}
					} )
					.catch( error => {
						console.error( 'Error: ' + JSON.stringify( error ) );

					} )
			},
			clearAlert( bvModalEvt ) {
				this.alertMessage = '';
				this.responseVariant = '';
				this.alertShow = false;
				if ( this.canceled ) {
					this.$router.push( { name: 'donations' } );
				}
			}
		},
	}
</script>

<style scoped>
	input#date[readonly="readonly"] {
		background-color: white;
	}

	.slideshow img.img-small {
		width: 1920px;
		height: 361px;
	}

    .donation-bottom{
        z-index: -1;
    }
</style>
